import FutureImage1 from '../../assets/future1.svg';
import FutureImage2 from '../../assets/future2.svg';
import MobileFutureImage1 from '../../assets/mobile-future1.svg';
import ContentPageInfo from '../../components/organisms/content-page-info';
import PageLayout from '../../components/templates/page';
import useResponsive from '../../hooks/use-responsive';
import { Container, ImageFutureMarket, WrapperFutureMarketInfo } from './styles';

function FutureMarket() {
  const { isTablet } = useResponsive();

  const title = 'Dê um crop nas suas \n contas mensais';
  const subTitle = `Apresentamos o Encolhe Contas™. Com ele \n você pode economizar em contas recorrentes \n como água, gás, energia e telefone. A cada dia \n estamos buscando novos benefícios para que \n sobre uma graninha no fim do mês.`;
  const preTitle = 'Encolhe><Contas';

  return (
    <PageLayout>
      <Container>
        {!isTablet && (
          <WrapperFutureMarketInfo width='15%' justify='flex-start'>
            <ImageFutureMarket source={FutureImage1} alt='future-market-icon-1-alt' />
          </WrapperFutureMarketInfo>
        )}

        <WrapperFutureMarketInfo width={isTablet ? '100%' : '68%'} justify='center'>
          {isTablet && (
            <ImageFutureMarket source={MobileFutureImage1} alt='future-market-icon-1-alt' />
          )}
          <ContentPageInfo centerAlign preTitle={preTitle} title={title} subTitle={subTitle} />
        </WrapperFutureMarketInfo>

        {!isTablet && (
          <WrapperFutureMarketInfo width='15%' justify='flex-end'>
            <ImageFutureMarket source={FutureImage2} alt='future-market-icon-2-alt' />
          </WrapperFutureMarketInfo>
        )}
      </Container>
    </PageLayout>
  );
}

export default FutureMarket;
