import sendEmailService from '../index';
import { IEmailAcknowledgmentIndicatorProps } from './types';

function sendEmailAcknowledgmentIndicator({
  email,
  companyName,
}: IEmailAcknowledgmentIndicatorProps) {
  const subject = '[Conta Futuro] - Obrigada por indicar a Conta Futuro!';
  const greetings = 'Obrigada por indicar a Conta Futuro!';

  const message = `Nossos especialistas entrarão em contato com a empresa ${companyName}.
  Serão apresentados todos os benefícios que a Conta Futuro agrega para as empresas participantes e,
  principalmente, os que gera para os funcionários`;

  return sendEmailService({ email, subject, greetings, message });
}

export default sendEmailAcknowledgmentIndicator;
