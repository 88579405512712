const mobileSScreen = '320px';
const mobileMScreen = '375px';
const mobileLScreen = '425px';
const tabletScreen = '768px';
const laptopScreen = '1024px';
const laptopLScreen = '1440px';
const desktopScreen = '2560px';

export {
  mobileSScreen,
  mobileMScreen,
  mobileLScreen,
  tabletScreen,
  laptopScreen,
  laptopLScreen,
  desktopScreen,
};
