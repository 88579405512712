import Divisor from '../../atoms/divisor';
import { Container, TextInfo, WrapperDivisor } from './styles';

function InfoContaFuturo() {
  return (
    <Container>
      <TextInfo>
        A CONTA FUTURO TECNOLOGIA SOLUCOES FINANCEIRAS E SERVICOS LTDA (CNPJ 38.064.618/0001-98) é
        uma fintech que facilita o acesso de clientes a soluções financeiras. Atuamos como
        correspondente bancário, atividade regulada pelo Banco Central do Brasil, nos termos da
        Resolução nº 3.954, de 24 de fevereiro de 2011.
      </TextInfo>
      <WrapperDivisor>
        <Divisor />
      </WrapperDivisor>
    </Container>
  );
}

export default InfoContaFuturo;
