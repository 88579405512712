import styled from 'styled-components';

import Text from '../../atoms/text';

interface IInputProps {
  error?: boolean;
}

export const Container = styled.div<IInputProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ error }) => !error && '14px'};
`;

export const Label = styled(Text)`
  color: ${({ theme }) => theme.bluishGray};
`;

export const Error = styled(Text)`
  color: ${({ theme }) => theme.lightRed};
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
`;
