import { Form } from 'formik';
import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';

export const FormContainer = styled(Form)`
  display: flex;
  width: 560px;
  height: 710px;

  background-color: white;
  padding: 30px;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 38px;
  box-shadow: 0 1px 10px rgba(205, 205, 212, 0.5);

  overflow-x: hidden !important;

  @media (max-width: ${tabletScreen}) {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
  }
`;
