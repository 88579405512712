import styled from 'styled-components';

import Button from '../../atoms/button';
import Text from '../../atoms/text';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

export const OptionMenu = styled(Text)`
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
`;

export const WrapperOptionMenu = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 32px;
  margin-right: 37px;
`;

export const WrapperDownloadButton = styled.div`
  width: 100%;
  height: 50%;
`;

export const WrapperKnowMore = styled(Button)`
  margin-left: 10px;
  height: 50%;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 0 10px;

  a {
    align-self: center;
    justify-content: center;
    display: flex;
    margin: 0 5px;
    color: ${({ theme }) => theme.primary};
  }
`;
