import Image from '../../atoms/image';
import { Container, InfoBenefit, TitleBenefit } from './styles';
import { IBenefitProps } from './types';

function Benefit({ imageSource, info, title }: IBenefitProps) {
  return (
    <Container>
      <Image source={imageSource} alt='benefit-img' />
      <>
        <TitleBenefit>{title} </TitleBenefit>
        <InfoBenefit>{info}</InfoBenefit>
      </>
    </Container>
  );
}

export default Benefit;
