import useResponsive from '../../../hooks/use-responsive';
import Contact from '../../molecules/contact';
import Copyright from '../../molecules/copyright';
import InfoContaFuturo from '../../molecules/info-conta-futuro';
import Social from '../../molecules/social';
import Stores from '../../molecules/stores';
import { Container, ContentTop, WrapperContacts } from './styles';

function FooterContent() {
  const { isMobile, isTablet } = useResponsive();
  return (
    <Container>
      <ContentTop>
        {isMobile || isTablet ? (
          <>
            <WrapperContacts>
              <Contact />
            </WrapperContacts>
            <Stores isVertical />
            <WrapperContacts>
              <Social />
            </WrapperContacts>
          </>
        ) : (
          <>
            <WrapperContacts>
              <Social />
            </WrapperContacts>
            <WrapperContacts>
              <Contact />
            </WrapperContacts>
            <Stores isVertical />
          </>
        )}
      </ContentTop>
      <InfoContaFuturo />
      <Copyright />
    </Container>
  );
}

export default FooterContent;
