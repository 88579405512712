import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${tabletScreen}) {
    flex-direction: column;
  }
`;

export const WrapperBenefit = styled.div`
  width: 20%;

  @media (max-width: ${tabletScreen}) {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
  }
`;
