import styled from 'styled-components';

import { tabletScreen } from '../../utils/responsive';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${tabletScreen}) {
    margin-top: 20%;
  }
`;

export const WrapperAntecipationInfo = styled.div`
  display: flex;

  overflow-x: hidden;

  @media (max-width: ${tabletScreen}) {
    width: 100%;
    flex-direction: column;
  }
`;
