import styled from 'styled-components';

import Button from '../../atoms/button';

interface IContainerProps {
  justify: string;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  padding: 58px 0px 8px 0;
  width: 100%;
  justify-content: ${({ justify }) => justify};
`;

export const IndicateCompanyButton = styled(Button)`
  width: auto;
  padding: 8px 16px;

  color: ${({ theme }) => theme.secundary};
`;
