import { Link } from 'react-router-dom';

import useResponsive from '../../../hooks/use-responsive';
import { Container, TextCopyRight, TextLink, WrapperTerms } from './styles';

function Copyright() {
  const { isTablet } = useResponsive();

  const currentYear = new Date().getFullYear();
  return (
    <Container>
      {!isTablet && (
        <TextCopyRight>© {currentYear} ContaFuturo. Todos os Direitos Reservados.</TextCopyRight>
      )}

      <WrapperTerms>
        <Link to='/privacy-politics'>
          <TextLink>Política de Privacidade</TextLink>
        </Link>
        <Link to='/terms-of-use'>
          <TextLink>Termos e Condições</TextLink>
        </Link>
      </WrapperTerms>

      {isTablet && (
        <TextCopyRight>© {currentYear} ContaFuturo. Todos os Direitos Reservados.</TextCopyRight>
      )}
    </Container>
  );
}

export default Copyright;
