import styled from 'styled-components';

export const Container = styled.ul`
  list-style-position: inside;
  list-style: none;
  margin-left: 0px;

  li {
    font-size: 16px;
    line-height: 32px;
    font-family: 'HalyardTextRegular';
  }
`;
