import CookieConsent from 'react-cookie-consent';

import { TextCookie } from './styles';

function CookieConsentConta() {
  const containerStyle = {
    left: '5%',
    width: '90%',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 10,
  };

  const buttonStyle = {
    backgroundColor: '#264042',
    borderRadius: 5,
    color: '#fff',
  };

  return (
    <CookieConsent
      containerClasses='shadow-container'
      buttonClasses='button-cookie'
      style={containerStyle}
      expires={150}
      buttonStyle={buttonStyle}
      buttonText='Continuar e fechar'
    >
      <TextCookie>
        Utilizamos cookies e tecnologias semelhantes para melhorar sua experiência em nosso site.
        Você pode saber mais sobre isso em nossa{' '}
        <a href='/privacy-politics'>Política de Privacidade</a> e, ao continuar navegando, você
        concorda com estas condições.
      </TextCookie>
    </CookieConsent>
  );
}

export default CookieConsentConta;
