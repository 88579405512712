import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  border: 3px solid #000;
  height: 100%;
`;

export const LabeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.primary};
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-left: 3px solid #000;
  background-color: #fff;
`;
export const Label = styled.h2`
  font-size: 16px;
  font-family: halyard-display, sans-serif;
  font-weight: 700;
`;
