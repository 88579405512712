import Benefit from '../../molecules/benefit';
import { benefits } from './constants';
import { Container, WrapperBenefit } from './styles';

function BenefitsContent() {
  return (
    <Container>
      {benefits.map(({ id, title, imageBenefitSource, subTitle }) => (
        <WrapperBenefit key={id}>
          <Benefit title={title} imageSource={imageBenefitSource} info={subTitle} />
        </WrapperBenefit>
      ))}
    </Container>
  );
}

export default BenefitsContent;
