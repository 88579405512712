import styled, { css } from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import Text from '../../atoms/text';

interface IContainerHeaderProps {
  enableBox: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

export const OptionMenu = styled(Text)`
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }

  @media (max-width: ${tabletScreen}) {
    font-size: 42px;
  }

  a {
    color: ${({ theme }) => theme.secundary};
  }
`;

export const WrapperOptionMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 32px;
  padding: 25px 20px;
  margin-top: 20px;
`;

export const WrapperDownloadButton = styled.div`
  width: 100%;
  height: 50%;
`;

export const MenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const MenuButtonOppend = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  border: none;
  padding: 8px;
  cursor: pointer;
`;

export const WrapperMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 120vh;
  background-color: #fff;
  z-index: 10000;
  overflow: hidden;
`;

export const Divisor = styled.div`
  width: 90vw;
  height: 1px;
  background-color: ${({ theme }) => theme.secundary};
  opacity: 0.5;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
`;

export const ContainerLogoHeader = styled.div<IContainerHeaderProps>`
  display: flex;
  align-items: center;

  @media (max-width: ${tabletScreen}) {
    img {
      height: 23px !important;
    }
  }

  ${({ enableBox }) =>
    enableBox &&
    css`
      img {
        height: 16px;
      }
    `}
`;
