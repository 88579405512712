import styled, { css } from 'styled-components';

import { IInputProps } from './types';

export const inputStyle = css<IInputProps>`
  background: ${({ theme, error }) => (error ? theme.transparentRed : theme.semiWhite)};
  color: ${({ theme }) => theme.darkGreen};

  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, error }) => (error ? theme.lightRed : theme.lightGray)};
  border-radius: 8px;
  padding: 11px 15px;

  &:focus {
    border-color: ${({ theme }) => theme.primary};
  }
`;

export const InputContent = styled.input<IInputProps>`
  ${inputStyle}
`;
