import OkWoman from '../../assets/ponte-salario.svg';
import Image from '../../components/atoms/image';
import ContentPageInfo from '../../components/organisms/content-page-info';
import PageLayout from '../../components/templates/page';
import useResponsive from '../../hooks/use-responsive';
import { Container, WrapperCreditlInfo } from './styles';

function PonteSalario() {
  const { isMobile, isTablet } = useResponsive();

  const title = 'Faça a ponte e fuja \n do cheque especial';
  const subTitle = `Com a Ponte Salário da ContaFuturo você pode ter acesso a \n ao equivalente a 40% do seu salário mensal e cobrir \n eventuais necessidades sem a necessidade de contrair \n empréstimos ou cair no cheque especial.`;
  const preTitle = `Ponte-Salário: Antecipe até 40% dos seus rendimentos`;

  return (
    <PageLayout>
      <Container>
        <ContentPageInfo
          title={title}
          subTitle={subTitle}
          preTitle={preTitle}
          imageInTitle={
            (isMobile || isTablet) && <Image source={OkWoman} alt='ok-woman-alt' height='160px' />
          }
        />
        {!isMobile && !isTablet && (
          <WrapperCreditlInfo>
            <Image style={{ maxHeight: '582px' }} source={OkWoman} alt='ok-woman-alt' />
          </WrapperCreditlInfo>
        )}
      </Container>
    </PageLayout>
  );
}

export default PonteSalario;
