import ScrollToPageButton from '../../atoms/scroll-to-page-button';
import { Container, TextContact, WrapperContact, WrapperContacts } from './styles';

function Contact() {
  return (
    <Container>
      <WrapperContacts>
        <span>Conta Futuro</span>
        {/* <WrapperContact>
          <ScrollToPageButton scrollTo='CashPass'>
            <TextContact>Cashpass</TextContact>
          </ScrollToPageButton>
        </WrapperContact> */}
        <WrapperContact>
          <ScrollToPageButton scrollTo='ZeraDebito'>
            <TextContact>Zera Débito</TextContact>
          </ScrollToPageButton>
        </WrapperContact>
        <WrapperContact>
          <ScrollToPageButton scrollTo='PonteSalario'>
            <TextContact>Ponte Salário</TextContact>
          </ScrollToPageButton>
        </WrapperContact>
        {/* <WrapperContact>
          <ScrollToPageButton scrollTo='faqPage'>
            <TextContact>Ajuda</TextContact>
          </ScrollToPageButton>
        </WrapperContact> */}
      </WrapperContacts>

      <WrapperContacts>
        <span>Fale com a gente</span>
        <WrapperContact>
          <TextContact>+55 85 99108-1016</TextContact>
        </WrapperContact>

        <WrapperContact>
          <TextContact>ola@contafuturo.com.br</TextContact>
        </WrapperContact>
      </WrapperContacts>
    </Container>
  );
}

export default Contact;
