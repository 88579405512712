import styled, { css } from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';

interface ISubTitleComponent {
  textAlign: string;
  isFirstPageFontStyle?: boolean;
}

export const SubTitleComponent = styled.p<ISubTitleComponent>`
  font-family: 'HalyardDisplay';
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 40px;
  white-space: pre-line;
  text-align: ${({ textAlign }) => textAlign};

  color: ${({ theme }) => theme.secundary};

  ${({ isFirstPageFontStyle }) =>
    isFirstPageFontStyle &&
    css`
      font-weight: 400;
      line-height: 36px;
    `}

  @media (max-width: ${tabletScreen}) {
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    white-space: normal;

    ${({ isFirstPageFontStyle }) =>
      isFirstPageFontStyle &&
      css`
        font-size: 22px;
        line-height: 31px;
      `}
  }
`;
