import { useEffect, useState } from 'react';

import LogoName from '../../../assets/logo-name.svg';
import useResponsive from '../../../hooks/use-responsive';
import Image from '../../atoms/image';
import ScrollToPageButton from '../../atoms/scroll-to-page-button';
import MenuHeader from '../../molecules/menu-header';
import MenuHeaderMobile from '../../molecules/menu-header-mobile';
import {
  ContainerHeader,
  ContainerHeaderContent,
  ContainerLogoHeader,
  ContainerMenuHeader,
} from './styles';

function Header() {
  const [offset, setOffset] = useState(0);
  const toEnablebox = offset > 250;
  const { isMobile } = useResponsive();

  const handleScroll = () => setOffset(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <ContainerHeader enableBox={toEnablebox}>
      <ContainerHeaderContent>
        <ScrollToPageButton scrollTo='homePage'>
          <ContainerLogoHeader enableBox={toEnablebox}>
            <Image source={LogoName} alt='logo-conta' />
          </ContainerLogoHeader>
        </ScrollToPageButton>
        {isMobile ? (
          <ContainerMenuHeader>
            <MenuHeaderMobile />
          </ContainerMenuHeader>
        ) : (
          <ContainerMenuHeader>
            <MenuHeader />
          </ContainerMenuHeader>
        )}
      </ContainerHeaderContent>
    </ContainerHeader>
  );
}

export default Header;
