// FELIPE QUE FEZ

import { useEffect } from 'react';

import { Container } from './styles';

function TermsOfUseComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <h1 style={{ textAlign: 'center' }}> TERMOS E CONDIÇÕES DE USO</h1>
      <br />
      <p>
        {' '}
        Estes termos e condições regulam o uso do nosso site (
        <a href='https://www.contafuturo.com'>www.contafuturo.com</a>), ou aplicativo, denominados
        em conjunto “<u>Plataforma</u>”, bem como dos serviços e produtos disponibilizados pela
        Conta Futuro Tecnologia Soluções Financeiras e Serviços Ltda. (“<u>Contafuturo</u>”),
        inscrita no CNPJ/ME sob nº 38.064.618/0001-98, com sede na cidade do Eusebio, estado do
        Ceará, na Rua Calixto Machado, 21, Sala N Altos, Pires Façanha, Eusebio – CE, 61760-000.
      </p>
      <p>
        {' '}
        <b>
          AVISO: LEIA ESTES TERMOS CUIDADOSAMENTE ANTES DE CONTINUAR UTILIZANDO NOSSA PLATAFORMA OU
          DE UTILIZAR QUAISQUER DE NOSSOS SERVIÇOS.
        </b>
      </p>
      <p> Os novos Termos e Condições de Uso entrarão em vigor imediatamente após a publicação. </p>
      <p>
        {' '}
        Estes Termos de Uso (“Termos”) estão divididos em <b>(i)</b> Condições Gerais e <b>(ii)</b>{' '}
        Condições Adicionais, <b>(iii)</b> Anexo com informações sobre as Instituições Financeiras
        Parceiras. Em conjunto com os demais contratos e a nossa{' '}
        <a href='https://www.contafuturo.com/privacy-politics'>Política de Privacidade</a>,
        estabelecem os termos e condições aplicáveis à utilização da nossa Plataforma.
      </p>
      <p>
        {' '}
        Leia este documento para compreender as regras que regem o uso do site e serviços da
        Contafuturo.
      </p>
      <p>
        <b>ACEITAÇÃO DOS TERMOS</b>
      </p>
      <p>
        {' '}
        VOCÊ CONFIRMA QUE, AO SE CADASTRAR E UTILIZAR QUAISQUER DAS FUNCIONALIDADES DA PLATAFORMA,
        TERÁ LIDO, ENTENDIDO E ACEITADO TODOS OS TERMOS E CONDIÇÕES LISTADOS ABAIXO. Caso não
        concorde com estes Termos de Uso (“<u>Termos</u>” ou “<u>Termo</u>”), Você não poderá
        utilizar a Plataforma. A Plataforma pode conter outros avisos de propriedade e informações
        protegidas por direitos autorais, cujos termos devem ser observados e cumpridos.
      </p>
      <p>
        {' '}
        Ao aceitar os dispositivos destes Termos, Você declara expressamente que domina a língua
        portuguesa, está familiarizado com o ambiente da Plataforma, possui capacidade civil, e que
        tem conhecimento suficiente dos produtos e serviços ofertados.
      </p>
      <p>
        {' '}
        Não coletamos ou solicitamos intencionalmente dados de identificação pessoal de pessoas
        menores de 18 anos, motivo pelo qual Você declara expressamente ter, no mínimo, (i) 18
        (dezoito) anos de idade; ou (ii) 16 (dezesseis) anos de idade, desde que seja emancipado ou
        que esteja sob supervisão e autorização do adulto responsável, para prosseguir na navegação
        da Plataforma. Caso Você seja menor de idade ou, se tiver entre 16 (dezesseis) e 18
        (dezoito) anos, não seja emancipado ou não esteja sob supervisão e autorização do adulto
        responsável, Você não deverá utilizar a Plataforma, não tente se registrar nos Serviços nem
        nos envie quaisquer dados pessoais.
      </p>
      <p>
        {' '}
        Se acreditar que uma pessoa menor de 18 anos pode ter nos fornecido seus Dados Pessoais,
        entre em contato conosco em{' '}
        <a href='mailto:legal@contafuturo.com.br'>legal@contafuturo.com.br</a>.
      </p>
      <p>
        <b>COMO A CONTAFUTURO ATUA</b>
      </p>
      <p>
        {' '}
        Nós não somos uma instituição financeira, companhia de seguros, operadora de meios de
        pagamento ou bandeira de cartão de crédito. Nós atuamos da seguinte maneira:
      </p>
      <ul style={{ paddingLeft: '8%', paddingRight: '5%' }}>
        <li>
          {' '}
          como sociedade prestadora de serviços que atua como correspondente bancário (conforme
          Resolução nº 3.954, de 24 de fevereiro de 2011, do Conselho Monetário Nacional, conforme
          alterada), <u>com o fim de facilitar o processo de contratação</u>, pelos clientes e
          usuários da Plataforma, <u> de empréstimos e/ou financiamentos (melhor descrito</u> nos
          Termos e Condições Adicionais (mais abaixo){' '}
          <u>
            junto a “Instituições Financeiras Parceiras” (instituições financeiras e demais
            instituições autorizadas a funcionar pelo Banco Central do Brasil, com quem a
            Contafuturo mantém relacionamento, descritas no Anexo mais abaixo destes Termos;
          </u>
        </li>
        <li>
          {' '}
          <u>
            como <i>marketplace</i>
          </u>{' '}
          ofertando e hospedando espaço na Plataforma{' '}
          <u>para que terceiros possam vender produtos e/ou serviços diretamente aos usuários</u>,
          atuando a Contafuturo apenas como intermediária dessa relação através da Contafuturo
          Store.
        </li>
      </ul>
      <p>
        <b>UTILIZAÇÃO DA PLATAFORMA</b>
      </p>
      <p>
        {' '}
        É possível navegar por uma parte da Plataforma sem estar registrado na conta de usuário,
        caso em que serão aplicáveis somente as condições gerais abaixo descritas. Porém, para
        acessar determinadas áreas, funcionalidades, produtos e serviços da Plataforma, além da
        atenção quanto às condições gerais, Você também deverá confirmar expressamente sua anuência
        com os Termos e Condições Adicionais, presentes no Anexo I à estes Termos, antes do seu
        primeiro acesso.
      </p>
      <p>
        <b>PRIVACIDADE E SEGURANÇA</b>
      </p>
      <p>
        {' '}
        A Contafuturo se compromete com a privacidade de seus usuários nos termos de sua política de
        privacidade, disponível em{' '}
        <a href='https://www.contafuturo.com/privacy-politics'>
          https://www.contafuturo.com/privacy-politics
        </a>{' '}
        (“<u>Política de Privacidade</u>”), que é parte integrante destes Termos.
      </p>
      <p>
        {' '}
        Você não compartilhará a sua conta ou senha com ninguém, e você deve mantê-las em segurança.
        Você é responsável por qualquer atividade associada à sua conta. Você concorda em notificar
        imediatamente a Contafuturo no caso de qualquer uso não autorizado da sua conta.
      </p>
      <p>
        <b>CONDIÇÕES GERAIS</b>
      </p>
      <p>
        {' '}
        Dependendo do serviço contratado por Você poderão ser aplicadas diferentes condições
        comerciais (“<u>Condições Comerciais</u>”), podendo inclusive serem cobrados valores
        diretamente de Você. Tais Condições Comerciais deverão ser verificadas antes da contratação
        do respectivo serviço.
      </p>
      <p>
        {' '}
        Em alguns casos, poderemos receber remunerações das Instituições Financeiras Parceiras,
        anunciantes e outros parceiros para que eles possam ser listados na nossa Plataforma. No
        caso de contratação de serviços junto às Instituições Financeiras Parceiras, estas poderão
        cobrar taxas de Você, previamente informadas, sem qualquer responsabilidade ou envolvimento
        da Contafuturo.
      </p>
      <p> Ao utilizar a Plataforma, Você expressamente reconhece que Nós:</p>
      <ul style={{ paddingLeft: '8%', paddingRight: '5%' }}>
        <li>
          {' '}
          Não somos responsáveis pelo cumprimento das normas e requisitos legais por parte das
          Instituições Financeiras Parceiras, anunciantes e outros parceiros indicados na
          Plataforma. Você é o único responsável pela verificação prévia da regularidade e
          idoneidade desses agentes;
        </li>
        <li>
          {' '}
          Não atuamos e nem substituímos consultores e assessores especializados na contratação de
          serviços financeiros, mesmo que tais serviços sejam indicados na Plataforma;
        </li>
        <li>
          {' '}
          Não somos responsáveis pelas decisões financeiras que Você possa vir a fazer por meio da
          Plataforma;
        </li>
        <li>
          {' '}
          Não garantimos que as Instituições Financeiras Parceiras cumpram suas obrigações
          contratuais e legalmente estabelecidas; Não asseguramos a continuidade dos serviços ou a
          manutenção das ofertas e cotações, sem prejuízo da boa conclusão dos serviços já
          iniciados;
        </li>
        <li>
          {' '}
          Não atuamos em seu nome, em nome das Instituições Financeiras Parceiras ou de quaisquer
          terceiros, mas somente disponibilizamos uma plataforma para facilitar a contratação de
          produtos financeiros oferecidos pelas Instituições Financeiras Parceiras; e
        </li>
        <li>
          {' '}
          Não prometemos qualquer forma de rentabilidade ou de previsibilidade de taxas, tarifas,
          ganhos, lucros, perdas ou condições de mercado.
        </li>
      </ul>
      <p>
        <b>DISPONIBILIDADE DA PLATAFORMA</b>
      </p>
      <p>
        {' '}
        Nós poderemos alterar o conteúdo da Plataforma a qualquer momento, sem prévio aviso, a fim
        de mantê-lo atualizado e completo, livre de quaisquer defeitos ou vírus. Além disso, iremos
        empreender nossos melhores esforços para garantir a maior disponibilidade possível da
        Plataforma e de seus serviços.
      </p>
      <p>
        {' '}
        O acesso à Plataforma pode ser interrompido, suspenso ou ficar intermitente temporariamente,
        sem qualquer aviso prévio, em caso de falha de sistema, manutenção, atualização, alteração
        de sistemas, ou por motivos que escapem ao nosso controle. No entanto, não somos
        responsáveis por qualquer indisponibilidade da Plataforma ou de seus serviços, por qualquer
        período ou momento.
      </p>
      <p>
        {' '}
        Nós poderemos decidir interromper permanentemente o acesso à Plataforma e os serviços
        ofertados. Nesta hipótese, porém, Nós tomaremos providências para que os serviços já
        iniciados tenham conclusão ou sejam adequadamente migrados sem prejuízos às partes
        envolvidas.
      </p>
      <p>
        <b>CONDUTA DOS USUÁRIOS E SANÇÕES</b>
      </p>
      <p> Você concorda em não utilizar a Plataforma para:</p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li>
          {' '}
          Violar ou solicitar a violação de qualquer lei ou regulamento local, estadual ou nacional
          aplicável;
        </li>
        <li>
          {' '}
          Violar os direitos de qualquer terceiro, incluindo, entre outros, direitos de propriedade
          intelectual, direitos contratuais e de privacidade ou direitos de publicidade;
        </li>
        <li>
          {' '}
          Interferir ou interromper a Plataforma, servidores de computador ou placas acessíveis por
          meio do website;
        </li>
        <li>
          {' '}
          Intencionalmente, buscar informações, obter cópias ou modificar arquivos ou outros dados
          ou senhas que pertencem a outros usuários sem permissão;
        </li>
        <li>
          {' '}
          Coletar nomes, endereços de e-mail ou outra informação pessoal de outros usuários para
          qualquer propósito;
        </li>
        <li>
          {' '}
          Assumir a identidade de qualquer pessoa ou falsamente declarar ou adulterar sua afiliação
          a uma pessoa ou entidade;
        </li>
        <li>
          {' '}
          Permitir fragilidades à Plataforma com equipamentos incompatíveis, serviço de conexão à
          Internet sem antivírus e firewall;
        </li>
        <li>
          {' '}
          Violar o sigilo dos dados em relação a terceiros, sem utilizá-la de um modo individual;
        </li>
        <li> Realizar ou permitir que realize engenharia reversa.</li>
        <li>
          {' '}
          Injuriar, caluniar, abusar, molestar, perseguir, ameaçar ou violar os direitos (tais como
          direitos de privacidade e publicidade) de terceiros;
        </li>
        <li> Fazer uso de linguagem preconceituosa difamatória ou linguagem ofensiva;</li>
        <li> Discutir ou incitar atividade ilegal ou a prática de crimes de qualquer natureza;</li>
        <li>
          {' '}
          Fazer uso de linguagem explícita/obscena ou solicitar/postar imagens sexualmente
          explícitas (reais ou simuladas);
        </li>
        <li>
          {' '}
          Promover informação que saiba ser falsa ou enganosa ou que promova ou facilite atividades
          ilegais, pirataria ou conduta abusiva, ameaçadora, obscena, difamatória ou acusatória;
        </li>
        <li> Praticar qualquer tipo de fraude; e</li>
        <li>
          {' '}
          Envolver-se ou facilitar a transmissão em massa não solicitada de e-mails ou spams.
        </li>
      </ul>
      <p>
        {' '}
        Caso Você utilize a Plataforma para cometer qualquer dos atos acima, a Contafuturo poderá, a
        seu exclusivo critério, cumulativamente (quando aplicável):
      </p>
      <ul style={{ paddingLeft: '8%', paddingRight: '5%' }}>
        <li> (a) Enviar advertência; e/ou</li>
        <li> (b) Suspender, temporária ou definitivamente, seu cadastro; e/ou</li>
        <li> (c) Cancelar definitivamente seu cadastro; e/ou</li>
        <li> (d) Suspender a prestação dos serviços; e/ou</li>
        <li> (e) Aplicar outra sanção razoável, conforme a natureza da infração; e/ou</li>
        <li>
          {' '}
          (f) Adotar as sanções legais pela configuração de delitos ou contravenções, ou de
          responsabilidade civil pelos danos causados aos outros usuários ou à Plataforma.
        </li>
      </ul>
      <p> As mesmas sanções acima serão aplicáveis nos casos em que:</p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li>
          {' '}
          (a) Você não cumprir qualquer dispositivo destes Termos e/ou qualquer política
          estabelecida e divulgada previamente pela Contafuturo;
        </li>
        <li> (b) Descumprir qualquer obrigação atribuível a Você por meio destes Termos;</li>
        <li>
          {' '}
          (c) Não for possível verificar a sua identidade ou se qualquer informação fornecida por
          Você for constatada, pela Contafuturo, incorreta ou incompleta;
        </li>
        <li>
          {' '}
          (d) A Contafuturo entender que os anúncios ou qualquer atitude sua tenha causado quaisquer
          danos a terceiros ou à própria Contafuturo, ou tenham a potencialidade de fazê-lo; ou
        </li>
        <li>
          {' '}
          (e) A Contafuturo suspeitar de qualquer ilegitimidade, fraude ou ato contrário às
          disposições destes Termos.
        </li>
      </ul>
      <p>
        <b>PROPRIEDADE INTELECTUAL</b>
      </p>
      <p>
        {' '}
        O uso comercial do nome, dos desenhos e da expressão “Contafuturo”e “Creditoo” como nome
        empresarial, marca, ou nome de domínio, bem como os conteúdos das telas relativas aos nossos
        serviços assim como os infográficos, artigos, colunas, widgets, programas, bancos de dados,
        documentos e demais utilidades e aplicações que permitem ao usuário acessar e usar sua conta
        de usuário são de nossa propriedade e estão protegidos por todas as leis e tratados
        aplicáveis.
      </p>
      <p>
        {' '}
        O Usuário reconhece que a Plataforma é protegida por leis e tratados internacionais
        relativos à propriedade intelectual. A organização, a estrutura, a sequência, a lógica, os
        fluxogramas, o código objeto e o código fonte da Plataforma constituem e/ou incorporam
        segredo de empresa, direitos autorais e/ou outros direitos de propriedade intelectual
        exclusivos e não publicados da Contafuturo, que se reserva e detém todos os direitos,
        títulos e interesses a eles relacionados.
      </p>
      <p>
        {' '}
        Não é permitido o uso comercial de qualquer marca, patente, direito autoral, segredo de
        negócio, software, informação confidencial ou qualquer outro direito de propriedade
        intelectual, registrado ou não, de titularidade da Contafuturo ou de terceiro que a tenha
        licenciado, sem a prévia e expressa autorização da Contafuturo.
      </p>
      <p>
        {' '}
        Igualmente, não é permitida a reprodução total ou parcial, para fins comerciais, de
        quaisquer marcas ou quaisquer outros direitos de propriedade intelectual da Contafuturo ou
        de terceiro que a tenha licenciado, sem a sua autorização prévia e expressa.
      </p>
      <p>
        {' '}
        Qualquer material disponibilizado na Plataforma é protegido por direitos de propriedade
        intelectual e poderá ser utilizado, caso haja autorização prévia e expressa da Contafuturo,
        apenas para fins de informação, desde que não possuam quaisquer propósitos comerciais.
      </p>
      <p> Não é permitida:</p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li>
          {' '}
          A modificação, cópia, distribuição, transmissão, reprodução, publicação e criação de
          trabalhos ou materiais derivados da Plataforma;
        </li>
        <li>
          {' '}
          A comercialização de qualquer informação, material, software, produto ou serviço da
          Contafuturo; e
        </li>
        <li>
          {' '}
          A utilização da Plataforma com propósitos imorais, ilegais, fora dos Termos da Plataforma
          ou com qualquer outro propósito que viole a legislação vigente.
        </li>
      </ul>
      <p>
        <b>WEBSITES E RECURSOS DE TERCEIROS</b>
      </p>
      <p>
        {' '}
        Em virtude de nossas parcerias ou por acreditarmos que possa ser de seu interesse, nós
        poderemos conectar em nossa Plataforma, a nosso exclusivo critério, outros websites e
        funcionalidades da internet, sem que isso signifique que esses websites sejam de propriedade
        ou operados por Nós.
      </p>
      <p>
        {' '}
        A Contafuturo não tem nenhum controle sobre tais websites, portanto, não será responsável ou
        obrigada, direta ou indiretamente, por qualquer dano ou perda causada ou alegada, com
        relação ao uso ou confiança em quaisquer de tais conteúdos, bens ou website disponíveis em
        ou por meio de qualquer tal website ou recurso.
      </p>
      <p>
        <b>LIMITAÇÃO DE RESPONSABILIDADE</b>
      </p>
      <p>
        {' '}
        Você reconhece que a Contafuturo se limita à prestação dos serviços informados nestes
        Termos, não sendo responsável por quaisquer obrigações fiscais, tributárias, trabalhistas,
        consumeristas ou de qualquer outra natureza referentes às transações realizadas por meio da
        Plataforma com as Instituições Financeiras Parceiras e outros terceiros.
      </p>
      <p>
        {' '}
        Na medida máxima permitida pela legislação aplicável, a Contafuturo também não será
        responsável por eventuais prejuízos, perdas ou danos, de qualquer espécie, causados por
        terceiros.
      </p>
      <p>
        {' '}
        A Contafuturo se exime de toda e qualquer responsabilidade, perante o usuário e terceiros,
        decorrente da utilização da Plataforma, incluindo, sem limitação, a realização de transações
        consideradas ilícitas, ou que infrinjam direitos de terceiros e/ou dados pessoais de
        terceiros utilizados sem autorização do seu respectivo titular.
      </p>
      <p>
        <b>ALTERAÇÃO DOS TERMOS</b>
      </p>
      <p>
        {' '}
        Estamos constantemente buscando melhorar os nossos Serviços e, assim, pode ser necessário
        alterar estes Termos juntamente com os Serviços. Reservamo-nos o direito de alterar os
        Termos a qualquer momento, mas se os alterarmos de forma substancial, levaremos isso a sua
        atenção ao colocar um aviso na Plataforma, por meio do endereço eletrônico fornecido por
        Você ou de postagem chamativa na Plataforma e/ou por outros meios.
      </p>
      <p>
        {' '}
        As novas alterações não vigorarão em relação às negociações e propostas iniciadas antes da
        publicação da nova versão dos Termos, única hipótese na qual será aplicável a redação
        anterior. Algumas condições específicas para certos serviços podem substituir ou
        complementar estes Termos, conforme alertado nos respectivos avisos legais disponíveis em
        outras áreas da Plataforma.
      </p>
      <p>
        {' '}
        Sugerimos fortemente que Você consulte eventuais atualizações de tempos em tempos. A última
        versão destes Termos estará disponível na Plataforma em{' '}
        <a href='https://www.contafuturo.com/terms-of-use'>www.contafuturo.com.br/terms-of-use</a>.
      </p>
      <p>
        {' '}
        Caso não concorde com as alterações, Você deverá descontinuar o uso da Plataforma e seu
        cadastro para finalidade da Plataforma será automaticamente cancelada pela Contafuturo, de
        acordo com as disposições previstas nestes Termos. Seus dados poderão ser mantidos pela
        Contafuturo caso haja alguma fundamentação e finalidade conforme legislação vigente.
      </p>
      <p>
        <b>GERAL</b>
      </p>
      <p>
        {' '}
        Estes Termos constituem o contrato integral entre Você e a Contafuturo com respeito à
        Plataforma e substituem todos os contratos e entendimentos anteriores entre o Você e a
        Contafuturo para este fim.
      </p>
      <p>
        {' '}
        Caso Nós deixemos de exercer qualquer direito previsto nestes Termos, isto não deverá ser
        interpretado como uma renúncia, abdicação ou revogação de disposição constante destes
        Termos.
      </p>
      <p>
        {' '}
        No caso de parte destes Termos serem consideradas inexequíveis, a parte inexequível será ao
        máximo possível interpretada de acordo com a lei aplicável para refletir as intenções
        originais das partes e o restante das disposições permanecerá em pleno vigor e efeito.
      </p>
      <p>
        <b>LEI APLICÁVEL E FORO DE ELEIÇÃO</b>
      </p>
      <p>
        {' '}
        Nós somos uma empresa brasileira e o Website e seus serviços são criados e mantidos em fiel
        cumprimento às leis brasileiras e demais tratados que são incorporados ao ordenamento
        jurídico brasileiro. Caso Você esteja usufruindo de nossos serviços fora do Brasil, Você
        será responsável pelo cumprimento das leis locais, na medida em que forem aplicáveis.
      </p>
      <p>
        {' '}
        Em caso de dificuldade de interpretação ou execução destes Termos, ou ainda, qualquer
        controvérsia relacionada ou decorrente do descumprimento destes Termos, tal questão será
        submetida ao foro da Comarca da Capital do Estado do Ceará, com a exclusão de qualquer
        outro, por mais privilegiado que seja.
      </p>
      <p>
        {' '}
        Para esclarecimento de quaisquer dúvidas, Você poderá entrar em contato com a Contafuturo
        por meio do e-mail{' '}
        <a href='mailto:contafuturo@Contafuturo.com.br'>contafuturo@contafuturo.com.br</a>.
      </p>
      <p style={{ textAlign: 'center' }}>
        <b>TERMOS E CONDIÇÕES ADICIONAIS</b>
      </p>
      <p>
        {' '}
        <b>Estes Termos e Condições Adicionais são complementares aos Termos acima.</b>
      </p>
      <p>
        <b>CADASTRO E REGISTRO</b>
      </p>
      <p>
        {' '}
        Para ter acesso a determinadas funcionalidades, serviços e produtos da Plataforma, Você
        deverá se cadastrar na Plataforma, através da inserção de seu e-mail, criação de senha
        pessoal e intrasferível e prestação das informações solicitadas. A Contafuturo não se
        responsabiliza pelo uso inadequado e divulgação destes dados para terceiros feitos por Você.
        Nem a Contafuturo, nem quaisquer de seus empregados ou prepostos, solicitarão que Você
        informe a sua senha como condição para a realização de qualquer atendimento.
      </p>
      <p>
        {' '}
        Seu login e senha são confidenciais e sigilosos, devendo Você adotar as cautelas e
        diligências necessárias para impedir a sua utilização indevida por terceiros.
      </p>
      <p>
        {' '}
        O cadastro deverá conter apenas informações verídicas, sob pena de Você ser responsabilizado
        civil e criminalmente por falsidade ideológica. A Contafuturo não se responsabiliza pela
        idoneidade ou capacidade civil do usuário, cabendo a ele agir com cautela e discernimento,
        respondendo, perante a Contafuturo e terceiros, por toda as atividades realizadas por meio
        da Plataforma e informações prestadas.
      </p>
      <p>
        {' '}
        Em caso de perda ou furto de sua senha de acesso, Você deverá imediatamente solicitar a
        alteração de sua senha na Plataforma. Caso tal solicitação não seja possível por qualquer
        motivo, Você deverá comunicar imediatamente a Contafuturo, sob pena de permanecer
        responsável por todas as ações na Plataforma feitas por meio do cadastro de tal usuário.
      </p>
      <p>
        {' '}
        A Contafuturo poderá negar ou cancelar o cadastro de qualquer usuário, independentemente de
        qualquer aviso ou notificação prévia, caso (i) alguma informação apresentada seja irregular,
        insuficiente ou falsa; (ii) seja identificado qualquer indício de fraude ou irregularidade
        no cadastro ou na utilização da Plataforma; ou (iii) o cadastro originário do usuário tenha
        sido cancelado por infração às condições e termos destes Termos.
      </p>
      <p>
        {' '}
        Você poderá, a qualquer momento, solicitar o cancelamento de seu cadastro na Plataforma.
      </p>
      <p>
        {' '}
        Você se compromete a notificar a Contafuturo imediatamente e por meio seguro a respeito de
        qualquer uso ou suspeita de uso não autorizado de sua conta, bem como seu acesso não
        autorizado por terceiros. Você será o único responsável pelas operações efetuadas em sua
        conta, uma vez que o acesso somente será possível mediante a inclusão da senha, que deverá
        ser de seu conhecimento e propriedade exclusiva.
      </p>
      <p>
        {' '}
        Não é permitida a manutenção de mais de um cadastro por um mesmo usuário, nem a criação de
        novo cadastro por usuário cujo cadastro original tenha sido suspenso temporária ou
        definitivamente em decorrência de infrações a estes Termos ou à legislação aplicável.
      </p>
      <p>
        {' '}
        Você deverá fazer o upload na Plataforma dos documentos solicitados pela Contafuturo, para
        que a Contafuturo possa verificar sua a identidade.
      </p>
      <p>
        <b>SOLICITAÇÃO DE PROPOSTAS OU COTAÇÕES</b>
      </p>
      <p>
        {' '}
        Através da Plataforma, Você poderá requerer que as <u>
          Instituições Financeiras Parceiras
        </u>{' '}
        enviem cotações ou propostas relativas a serviços e produtos financeiros que sejam do seu
        interesse. Ao fazer isso, Você expressamente reconhece que:
      </p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li>
          {' '}
          Nós poderemos enviar as informações fornecidas por Você para as Instituições Financeiras
          Parceiras e para outras entidades do grupo Contafuturo, em conformidade a nossa Política
          de Privacidade. Tais informações poderão ser usadas por entidades do grupo Contafuturo,
          pelas Instituições Financeiras Parceiras e por outras instituições a elas ligadas para
          determinarem o seu perfil de consumidor, seu histórico de crédito e outros fatores
          relevantes para que possam lhe enviar uma proposta adequada, incluindo, mas não se
          limitando para o fornecimento de crédito suplementar. As Instituições Financeiras
          Parceiras e entidades do grupo Contafuturo poderão entrar em contato com Você, através das
          formas de contato que Você nos concedeu ao se cadastrar como usuário da Plataforma;
        </li>
        <li>
          {' '}
          As Instituições Financeiras Parceiras e entidades do grupo Contafuturo poderão solicitar
          diretamente outras informações sobre Você, cuja coleta, guarda e tratamento não serão de
          nossa responsabilidade, se realizadas pelas Instituições Parceiras;
        </li>
        <li>
          {' '}
          Alguns serviços oferecidos pelas Instituições Financeiras Parceiras poderão gerar
          impostos, tarifas, emolumentos, custas, taxas e cobranças ou retenções específicas, legal
          ou contratualmente previstas, as quais não serão de nossa responsabilidade. Alguns
          serviços oferecidos por Nós ou por entidades do grupo Contafuturo poderão gerar impostos,
          tarifas, emolumentos, custas, taxas e cobranças ou retenções específicas, legal ou
          contratualmente previstas. Caberá a Você verificar as leis e contratos aplicáveis, para
          que tenha inteiro conhecimento de quaisquer retenções ou cobranças previstas e aplicáveis
          e impostas a quaisquer partes, sendo de exclusiva responsabilidade da parte a quem a lei
          ou o contrato atribuir responsabilidade; e
        </li>
        <li>
          {' '}
          Cada serviço oferecido por Nós, por entidades do grupo Contafuturo ou por Instituições
          Financeiras Parceiras possui termos contratuais e legais próprios, complementares aos
          presentes Termos, que deverão ser cuidadosamente verificados por Você antes da
          contratação.
        </li>
      </ul>
      <p>
        {' '}
        Vale ressaltar ainda que alguns serviços de nossa Plataforma só estarão disponíveis única e
        exclusivamente para funcionários ou empregados das instituições ou empresas que possuam
        Convênio com a Contafuturo ou a qualquer empresa de seu grupo societário. Caso Você tome
        conhecimento que sua empresa não é conveniada, poderá solicitar à área responsável desta,
        que entre em contato conosco para que possamos viabilizar uma parceria e disponibilizar tais
        serviços à Você.
      </p>
      <p>
        {' '}
        Nós não somos parte no contrato ou relação entre Você e as Instituições Financeiras
        Parceiras, e não podemos ser responsabilizados pelas obrigações ali assumidas. Tais
        obrigações serão de responsabilidade exclusiva das partes do contrato, na medida e forma em
        que tais responsabilidades forem alocadas.
      </p>
      <p>
        {' '}
        Caso Você deseje, deverá manifestar, por sua conta e risco, sua vontade em contratar um dos
        serviços ou produtos financeiros oferecidos pelas Instituições Financeiras Parceiras, dentre
        as opções disponibilizadas pelas Instituições Financeiras Parceiras em nossa Plataforma,
        conforme o valor, condições de prazo e pagamento aplicáveis. Em seguida, Você deverá
        fornecer as informações solicitadas, que, juntamente com informações extraídas de bases de
        dados públicas e/ou privadas disponíveis, servirão de fundamento para a análise de seu risco
        de crédito pelas Instituições Financeiras Parceiras.
      </p>
      <p>
        {' '}
        Caso Você queira obter uma proposta de crédito nova e/ou suplementar com a Instituição
        Parceira ou com entidades do grupo Contafuturo por meio da nossa Plataforma, deverá (i)
        autorizar o acesso da Instituição Parceira ou de entidades do grupo Contafuturo, conforme
        aplicável, às informações que lhe são relativas e que constem do Sistema de Informações de
        Crédito do Banco Central (“SCR”), dos órgãos de proteção ao crédito, de bases de dados
        públicas e privadas, conforme aplicável, e demais fontes que possam auxiliar na definição de
        seu risco de crédito; (ii) autorizar o compartilhamento das informações coletadas pela
        Instituição Parceira ou por entidade do grupo Contafuturo conosco e com as demais
        Instituições Financeiras Parceiras ou entidades do grupo Contafuturo, conforme previsto
        nestes Termos; e (iii) autorizar a execução de procedimentos de Know Your Client (“KYC”),
        inclusive aqueles dispostos nos termos das leis anticorrupção, antiterrorismo, contra a
        lavagem de capitais e a evasão de divisas, nos termos da regulamentação bancaria aplicável e
        sempre que necessário ou adequado.
      </p>
      <p>
        <b>INSTITUIÇÕES FINANCEIRAS PARCEIRAS</b>
      </p>
      <p>
        {' '}
        Somos correspondente bancário da seguinte instituição financeira: BMP MONEY PLUS SOCIEDADE
        DE CREDITO DIRETO S.A - (CNPJ 34.337.707/0001-00).
      </p>
    </Container>
  );
}

export default TermsOfUseComponent;
