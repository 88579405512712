import sendEmailService from '../index';
import { IEmailHrIndicatedCompanyProps } from './types';

function sendEmailHrContaFuturo({
  email,
  name,
  phone,
  hrContactName,
  hrContactEmail,
  hrContactPhone,
  companyName,
}: IEmailHrIndicatedCompanyProps) {
  const subject = '[Conta Futuro] - Indicaram a Conta Futuro';
  const greetings = 'Indicaram a Conta Futuro!';
  const message = `A Conta Futuro foi indicada por ${name}.
  E-mail: ${email}.
  Telefone: ${phone}.`;

  const considerations = `Para a empresa ${companyName}.
    RH: ${hrContactName}.
    E-mail: ${hrContactEmail}.
    Telefone: ${hrContactPhone}.`;

  const template = 'internal_email_template.hbs';

  return sendEmailService({
    email: 'comercial@contafuturo.com.br',
    subject,
    greetings,
    message,
    considerations,
    template,
  });
}

export default sendEmailHrContaFuturo;
