import { createGlobalStyle } from 'styled-components';

import HalyardDisplay from '../assets/fonts/HalyardDisplay.ttf';
import HalyardTextRegular from '../assets/fonts/HalyardTextRegular.ttf';

export const GlobalStyle = createGlobalStyle`

  @font-face {
          font-family: 'HalyardTextRegular';
          src: local('HalyardTextRegular'), local('HalyardTextRegular'),
          url(${HalyardTextRegular}) format('truetype');
  }

  @font-face {
          font-family: 'HalyardDisplay';
          src: local('HalyardDisplay'), local('HalyardDisplay'),
          url(${HalyardDisplay}) format('truetype');
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }


  body {
	 -webkit-font-smoothing: antialiased;
	}

  span {
    font-family: 'HalyardDisplay';

  }

  h1 {
    font-family: halyard-display, sans-serif;
    font-weight: 700;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
`;
