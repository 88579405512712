import styled, { css } from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';

interface IContainerHeaderProps {
  enableBox: boolean;
}

export const ContainerHeader = styled.div<IContainerHeaderProps>`
  background-color: ${({ theme }) => theme.primary};
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  align-items: center;
  max-width: 100vw;
  z-index: 2;
  padding: 0 10%;

  @media (max-width: ${tabletScreen}) {
    padding: 6px 18px;
    height: auto;
  }

  ${({ enableBox }) =>
    enableBox &&
    css`
      height: 84px;

      box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.1);
    `}
`;

export const ContainerHeaderContent = styled.div`
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  align-items: center;
  z-index: 2;

  @media (max-width: ${tabletScreen}) {
    padding: 6px 18px;
    height: auto;
  }
`;

export const ContainerMenuHeader = styled.div`
  height: 100%;
`;

export const ContainerLogoHeader = styled.div<IContainerHeaderProps>`
  display: flex;
  height: 100%;
  align-items: center;

  @media (max-width: ${tabletScreen}) {
    img {
      height: 23px !important;
    }
  }

  ${({ enableBox }) =>
    enableBox &&
    css`
      img {
        /* height: 16px; */
      }
    `}
`;
