import styled, { css } from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import { IContainerPageProps } from './types';

export const Container = styled.div<IContainerPageProps>`
  min-height: 100vh;
  max-width: 100vw;
  background-color: ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 10%;

  overflow-x: hidden;
  ${({ background }) =>
    background &&
    css`
      background-color: ${background};
    `}

  @media (max-width: ${tabletScreen}) {
  }
`;

export const ContainerContent = styled.div`
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${tabletScreen}) {
    margin-bottom: 1vh;
  }
`;
