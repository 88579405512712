import Image from '../../atoms/image';
import { LinkStore } from './styles';
import { IStoreProps } from './types';

function Store({ url, sourceImgStore }: IStoreProps) {
  return (
    <LinkStore href={url}>
      <Image source={sourceImgStore} alt='store-image' />
    </LinkStore>
  );
}

export default Store;
