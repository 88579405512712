export const theme = {
  primary: '#EEFFB1',
  secundary: '#000000',
  background: '#FFFFFF',
  green: '#264042',
  basicColor: '#594D6D',
  forestGreen: '#264042',
  bluishGray: '#878DBA',
  darkGreen: '#303F42',
  lightRed: '#F68D8D',
  transparentRed: '#F68D8D1A',
  lightGray: '#edeffa',
  semiWhite: '#fcfcff',
};
