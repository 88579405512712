import { useNavigate } from 'react-router-dom';

import { Container, IndicateCompanyButton } from './styles';
import { IActionsPageProps } from './types';

function ActionsPage({ justifyAlign }: IActionsPageProps) {
  const navigate = useNavigate();
  return (
    <Container justify={justifyAlign}>
      <IndicateCompanyButton onClick={() => navigate('/indicate')}>
        Indique para sua empresa
      </IndicateCompanyButton>
    </Container>
  );
}

export default ActionsPage;
