import styled from 'styled-components';

import Text from '../../atoms/text';

export const Container = styled.div`
  padding-top: 5%;
`;

export const WrapperDivisor = styled.div`
  padding: 4vh 0px 2vh;
`;

export const TextInfo = styled(Text)`
  opacity: 0.5;
  font-size: 14px;
  line-height: 20px;
`;
