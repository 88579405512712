import useResponsive from '../../../hooks/use-responsive';
import DownloadButton from '../../atoms/download-button';
import ScrollToPageButton from '../../atoms/scroll-to-page-button';
import {
  Container,
  OptionMenu,
  WrapperDownloadButton,
  WrapperKnowMore,
  WrapperOptionMenu,
} from './styles';

function MenuHeader() {
  const { isMobile } = useResponsive();

  return (
    <Container>
      {!isMobile && (
        <WrapperOptionMenu>
          {/* <ScrollToPageButton scrollTo='CashPass'>
            <OptionMenu>Cashpass</OptionMenu>
          </ScrollToPageButton> */}
          <ScrollToPageButton scrollTo='ZeraDebito'>
            <OptionMenu>Zera débito</OptionMenu>
          </ScrollToPageButton>
          <ScrollToPageButton scrollTo='PonteSalario'>
            <OptionMenu>Ponte salário</OptionMenu>
          </ScrollToPageButton>
          {/* <ScrollToPageButton scrollTo='faqPage'>
            <OptionMenu>Ajuda</OptionMenu>
          </ScrollToPageButton> */}
        </WrapperOptionMenu>
      )}
      <WrapperDownloadButton>
        <DownloadButton />
      </WrapperDownloadButton>
      <WrapperKnowMore>
        <a href='https://wa.me/+55085991081016' target='_blank' rel='noreferrer'>
          Saiba mais
        </a>
      </WrapperKnowMore>
    </Container>
  );
}

export default MenuHeader;
