import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';

import { ButtonDownloadApp, MobileLink } from './styles';

function DownloadButton() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const scrollToDigitalPage = () => {
    if (pathname === '/') {
      scroller.scrollTo('footer', {
        duration: 1500,
        delay: 100,
        smooth: true,
      });
    } else {
      navigate(`/#digitalPage`);
    }
  };

  return (
    <MobileLink
      href={!isMobile ? undefined : 'http://onelink.to/a6rbfc'}
      target='_blank'
      rel='noreferrer'
    >
      <ButtonDownloadApp onClick={scrollToDigitalPage}> Baixar o App </ButtonDownloadApp>
    </MobileLink>
  );
}

export default DownloadButton;
