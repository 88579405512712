import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import Text from '../../atoms/text';

interface IWrapperTextsInfo {
  textAlign?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PreTitle = styled(Text)``;

export const WrapperTextsInfo = styled.div<IWrapperTextsInfo>`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  justify-content: ${({ textAlign }) => textAlign};

  @media (max-width: ${tabletScreen}) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;
