import { TitleComponent } from './styles';
import { ITitleProps } from './types';

function Title({ children, textAlign, isFirstPageFontStyle, fontSize = '58px' }: ITitleProps) {
  return (
    <TitleComponent
      fontSize={fontSize}
      textAlign={textAlign}
      isFirstPageFontStyle={isFirstPageFontStyle}
    >
      {children}
    </TitleComponent>
  );
}

export default Title;
