import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';

export const Container = styled.div``;

export const WrapperContacts = styled.div`
  @media (max-width: ${tabletScreen}) {
    width: 100vw;
  }
`;

export const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${tabletScreen}) {
    width: 100vw;
    flex-direction: column;
  }
`;
