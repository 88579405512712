import { useTheme } from 'styled-components';

import Coins from '../../assets/coins.svg';
import MobileCoins from '../../assets/mobile-coins.svg';
import Image from '../../components/atoms/image';
import ContentPage from '../../components/organisms/content-page';
import PageLayout from '../../components/templates/page';
import useResponsive from '../../hooks/use-responsive';
import { Container } from './styles';

function Home() {
  const theme = useTheme();
  const { isTablet } = useResponsive();

  const title = 'Estamos em missão \n contra o dinheiro \n negativo';

  const subTitle =
    'Somos uma plataforma criada para construir \n ferramentas financeiras focadas na redução \n de custo e alívio das contas mensais.';

  return (
    <PageLayout background={theme.primary}>
      <Container>
        {isTablet && <Image source={MobileCoins} alt='coins-conta' height='131px' />}
        <ContentPage title={title} subTitle={subTitle} isFirstPageFontStyle>
          {!isTablet && <Image source={Coins} alt='coins-conta' />}
        </ContentPage>
      </Container>
    </PageLayout>
  );
}

export default Home;
