import { Route, Routes } from 'react-router-dom';

import Landing from '../pages';
import Indicate from '../pages/Indicate';
import PrivacyPolitics from '../pages/PrivacyPolitics';
import TermsOfUse from '../pages/TermsOfUse';

function AppRoutes() {
  return (
    <Routes>
      <Route path='/*' element={<Landing />} />
      <Route path='/indicate' element={<Indicate />} />
      <Route path='/privacy-politics' element={<PrivacyPolitics />} />
      <Route path='/terms-of-use' element={<TermsOfUse />} />
    </Routes>
  );
}

export default AppRoutes;
