import MissionImage from '../../assets/mission.svg';
import MobileMissionImage from '../../assets/mobile-mission.svg';
import MissionMobile from '../../components/atoms/mobile-misson';
import BenefitsContent from '../../components/organisms/benefits-content';
import PageLayout from '../../components/templates/page';
import useResponsive from '../../hooks/use-responsive';
import { Container, Mission, TextSubTitleBenefit } from './styles';

function Benefits() {
  const { isMobile } = useResponsive();

  return (
    <PageLayout>
      <Container>
        {isMobile ? <MissionMobile /> : <Mission source={MissionImage} alt='mission-alt' />}
        <TextSubTitleBenefit>
          Nós criamos ferramentas financeiras focadas na redução de custo e alívio das contas
          mensais
        </TextSubTitleBenefit>
        <BenefitsContent />
      </Container>
    </PageLayout>
  );
}

export default Benefits;
