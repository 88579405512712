import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';

export const DivWrapper = styled.div`
  flex-direction: row;
  display: flex;

  @media (max-width: ${tabletScreen}) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;
