import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import CookieConsentConta from './components/atoms/cookie-consent';
import Routes from './routes';
import { GlobalStyle } from './styles/global';
import { theme } from './styles/theme';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Routes />
        <CookieConsentConta />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
