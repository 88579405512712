import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';

import Header from '../components/organisms/header';
import Whatsapp from '../components/organisms/whatsapp';
import Benefits from './Benefits';
import CashPass from './CashPass';
import Digital from './Digital';
import FAQ from './FAQ';
import Footer from './Footer';
import FutureMarket from './FutureMarket';
import Home from './Home';
import PonteSalario from './PonteSalario';
import ZeraDebito from './ZeraDebito';

function Landing() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === '') {
      scroller.scrollTo('homePage', {});
    } else {
      const id = hash.replace('#', '');
      scroller.scrollTo(id, {});
    }
  }, [hash]);

  return (
    <>
      <Header />
      <Element name='homePage'>
        <Home />
      </Element>
      <Benefits />
      <Element name='ZeraDebito'>
        <ZeraDebito />
      </Element>
      <Element name='PonteSalario'>
        <PonteSalario />
      </Element>
      {/* <Element name='CashPass'>
        <CashPass />
      </Element> */}
      <FutureMarket />
      {/* <Element name='digitalPage'>
        <Digital />
      </Element> */}
      {/* <Element name='faqPage'>
        <FAQ />
      </Element> */}
      <Element name='footer'>
        <Footer />
      </Element>

      <Whatsapp />
    </>
  );
}

export default Landing;
