import React from 'react';

import { Field, FormikProps } from 'formik';

import { phoneNumberMask } from '../../../utils/forms';
import LabeledInput from '../../molecules/labeled-input';
import { IFormValues } from '../indicate-form/types';
import {
  FormTitle,
  InputContainer,
  RequiredFieldMessage,
  RowContainer,
  SendButton,
} from './styles';

function IndicateFormFields({ errors, touched }: FormikProps<IFormValues>) {
  return (
    <>
      <FormTitle>Quero indicar a Conta Futuro para o RH da minha empresa</FormTitle>

      <InputContainer>
        <Field
          label='Nome'
          name='name'
          required
          error={errors.name && touched.name && errors.name}
          component={LabeledInput}
        />

        <RowContainer>
          <Field
            label='E-mail'
            required
            error={errors.email && touched.email && errors.email}
            component={LabeledInput}
            name='email'
            type='email'
          />
          <Field
            label='Telefone'
            name='phone'
            type='tel'
            mask={phoneNumberMask}
            error={errors.phone && touched.phone && errors.phone}
            required
            component={LabeledInput}
          />
        </RowContainer>

        <Field
          label='Nome do contato do RH'
          name='hrContactName'
          required
          error={errors.hrContactName && touched.hrContactName && errors.hrContactName}
          component={LabeledInput}
        />

        <RowContainer>
          <Field
            label='E-mail do contato do RH'
            name='hrContactEmail'
            type='email'
            required
            error={errors.hrContactEmail && touched.hrContactEmail && errors.hrContactEmail}
            component={LabeledInput}
          />
          <Field
            label='Telefone do RH'
            name='hrContactPhone'
            type='tel'
            required
            mask={phoneNumberMask}
            error={errors.hrContactPhone && touched.hrContactPhone && errors.hrContactPhone}
            component={LabeledInput}
          />
        </RowContainer>

        <Field
          label='Nome da Empresa'
          name='companyName'
          required
          error={errors.companyName && touched.companyName && errors.companyName}
          component={LabeledInput}
        />
      </InputContainer>

      <SendButton type='submit'>Enviar</SendButton>
      <RequiredFieldMessage>*Campos obrigatórios</RequiredFieldMessage>
    </>
  );
}

export default IndicateFormFields;
