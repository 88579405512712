import styled, { css } from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';

interface ITitleComponent {
  textAlign: string;
  fontSize?: string;
  isFirstPageFontStyle?: boolean;
}

export const TitleComponent = styled.h1<ITitleComponent>`
  font-family: halyard-display, sans-serif;
  font-weight: 700;

  text-align: ${({ textAlign }) => textAlign};

  font-size: ${({ fontSize }) => fontSize};
  line-height: 60px;
  white-space: pre-line;
  text-decoration-line: underline;

  color: ${({ theme }) => theme.secundary};

  @media (max-width: ${tabletScreen}) {
    font-size: 27px;
    font-weight: bolder;
    line-height: 41px;
    margin-top: 12px;
    ${({ isFirstPageFontStyle }) =>
      isFirstPageFontStyle &&
      css`
        font-size: 32px;
        line-height: 49px;
      `}
  }
`;
