import { useMediaQuery } from 'react-responsive';

import { mobileLScreen, tabletScreen } from '../utils/responsive';

const useResponsive = () => {
  const isMobile = useMediaQuery({ maxWidth: mobileLScreen });
  const isTablet = useMediaQuery({ maxWidth: tabletScreen });

  return {
    isMobile,
    isTablet,
  };
};

export default useResponsive;
