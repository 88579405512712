import styled from 'styled-components';

import Image from '../../components/atoms/image';
import Text from '../../components/atoms/text';
import { tabletScreen } from '../../utils/responsive';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media (max-width: ${tabletScreen}) {
    padding-top: 5vh;
  }
`;

export const Mission = styled(Image)`
  object-fit: contain;
  width: 100%;
  margin: 1vh 0px;
`;

export const TextSubTitleBenefit = styled(Text)`
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin: 1vh 0px 13vh;

  @media (max-width: ${tabletScreen}) {
    font-size: 21px;
    line-height: 25px;
    margin: 4vh 0px 10vh;
  }
`;
