import zeraDebito from '../../assets/zeradebito.svg';
import Image from '../../components/atoms/image';
import ContentPageInfo from '../../components/organisms/content-page-info';
import PageLayout from '../../components/templates/page';
import useResponsive from '../../hooks/use-responsive';
import { Container, WrapperAntecipationInfo } from './styles';

function ZeraDebito() {
  const { isMobile, isTablet } = useResponsive();

  const title = 'Quite suas dívidas e dê \n um reset nas finanças';
  const subTitle = `Com o Zera Débito da ContaFuturo você pode trocar suas \n dívidas caras  e conseguir um fluxo de pagamentos mais \n adequado ao seu momento ou ainda quitar débitos e \n organizar suas finanças de uma forma mais programada.`;
  const preTitle = `ZeraDébito: Acabe com suas dívidas caras`;

  return (
    <PageLayout>
      <Container>
        {!isMobile && !isTablet && (
          <WrapperAntecipationInfo>
            <Image
              style={{ maxHeight: '486px', alignSelf: 'center' }}
              source={zeraDebito}
              alt='punctual-man-alt'
            />
          </WrapperAntecipationInfo>
        )}
        <ContentPageInfo
          centerAlign={false}
          title={title}
          subTitle={subTitle}
          preTitle={preTitle}
          imageInTitle={
            (isMobile || isTablet) && (
              <Image source={zeraDebito} alt='punctual-man-alt' height='160px' />
            )
          }
        />
      </Container>
    </PageLayout>
  );
}

export default ZeraDebito;
