import styled from 'styled-components';

import Button from '../../atoms/button';
import Image from '../../atoms/image';
import Text from '../../atoms/text';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SuccessfullySignal = styled(Image)`
  height: 163px;
  margin-top: 30px;
`;

export const FormTitle = styled(Text)`
  font-family: 'HalyardTextRegular';
  font-size: 24px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.secundary};

  margin-bottom: 25px;
`;

export const SendButton = styled(Button)`
  max-width: 317px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.secundary};
  border: none;
  font-size: 18px;
  line-height: 25px;

  margin-top: 24px;
`;
