import Wrapper from '../../atoms/Wrapper';
import ActionsPage from '../../molecules/actions-page';
import InfoPage from '../../molecules/info-page';
import InfosListPage from '../../molecules/infos-list-page';
import Stores from '../../molecules/stores';
import { Container, TextPreTitle, WrapperInfos } from './styles';
import { IContentPageInfoProps } from './types';

function ContentPageInfo({
  title,
  subTitle,
  preTitle,
  centerAlign,
  infos,
  showStores,
  imageInTitle,
  isFirstPageFontStyle,
  imageInTitleAfter,
}: IContentPageInfoProps) {
  return (
    <Container align={centerAlign ? 'center' : 'unset'}>
      {preTitle && (
        <Wrapper style={{ display: 'flex' }}>
          <TextPreTitle>{preTitle}</TextPreTitle>
        </Wrapper>
      )}
      <InfoPage
        centerAlign={centerAlign}
        title={title}
        subTitle={subTitle}
        imageInTitle={imageInTitle}
        imageInTitleAfter={imageInTitleAfter}
        isFirstPageFontStyle={isFirstPageFontStyle}
      />
      {infos && (
        <WrapperInfos>
          <InfosListPage infos={infos} />
        </WrapperInfos>
      )}
      {showStores ? (
        <Stores />
      ) : (
        <ActionsPage justifyAlign={centerAlign ? 'center' : 'flex-start'} />
      )}
    </Container>
  );
}

export default ContentPageInfo;
