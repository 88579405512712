import styled from 'styled-components';

export const DivisorContent = styled.hr`
  color: ${({ theme }) => theme.forestGreen};
  height: 1px;
  border-color: ${({ theme }) => theme.forestGreen};
  background-color: ${({ theme }) => theme.forestGreen};
  border-radius: 1px;
  opacity: 0.1;
`;
