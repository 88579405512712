import styled from 'styled-components';

import { tabletScreen } from '../../utils/responsive';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 80px 10% 45px;
  background-color: ${({ theme }) => theme.primary};

  @media (max-width: ${tabletScreen}) {
    padding: 49px 10% 45px;

    width: 100vw;
    flex-direction: column;
  }
`;
