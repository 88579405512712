import ContaFuturoApi from '../config';
import { IEmailBody } from './types';

const sendEmailService = (body: IEmailBody) => {
  const url = 'send-email';

  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  return ContaFuturoApi.post(url, body, config);
};

export default sendEmailService;
