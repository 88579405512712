import { SubTitleComponent } from './styles';
import { ISubTitleProps } from './types';

function SubTitle({ children, textAlign, isFirstPageFontStyle }: ISubTitleProps) {
  return (
    <SubTitleComponent textAlign={textAlign} isFirstPageFontStyle={isFirstPageFontStyle}>
      {children}
    </SubTitleComponent>
  );
}

export default SubTitle;
