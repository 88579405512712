import { useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';

import { WrapperContent } from './styles';
import { IScrollToPageProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-empty-function
function ScrollToPageButton({ scrollTo, children, closeMenu = () => {} }: IScrollToPageProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const scrollToPage = () => {
    closeMenu();
    if (pathname === '/') {
      scroller.scrollTo(scrollTo, {
        duration: 1500,
        delay: 100,
        smooth: true,
      });
    } else {
      navigate(`/#${scrollTo}`);
    }
  };

  return <WrapperContent onClick={scrollToPage}>{children}</WrapperContent>;
}

export default ScrollToPageButton;
