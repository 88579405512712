import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.primary};
  height: 68vh;
  display: flex;
  width: 40%;
  place-content: center;

  @media (max-width: ${tabletScreen}) {
    height: auto;
  }
`;
