import { useEffect } from 'react';

import { Element } from 'react-scroll';

import ArrowTop from '../../assets/arrow-top.svg';
import ThreeStars from '../../assets/three-stars.svg';
import Title from '../../components/atoms/title';
import Header from '../../components/organisms/header';
import IndicateForm from '../../components/organisms/indicate-form';
import Whatsapp from '../../components/organisms/whatsapp';
import useResponsive from '../../hooks/use-responsive';
import Footer from '../Footer';
import { ArrowUp, Container, Content, FormContainer, ThreeStar } from './styles';

function Indicate() {
  const { isTablet } = useResponsive();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Content>
          {!isTablet && <ArrowUp alt='arrow top' source={ArrowTop} />}

          <FormContainer>
            <Title fontSize='34px' textAlign='left'>
              {`Ajude a sua empresa a te ajudar!
              Indique a Conta Futuro para garantir os benefícios.`}
            </Title>
            <Element name='form-indication'>
              <IndicateForm />
            </Element>
          </FormContainer>

          {!isTablet && <ThreeStar alt='Three stars' source={ThreeStars} />}
        </Content>
      </Container>
      <Footer />
      <Whatsapp />
    </>
  );
}

export default Indicate;
