import ShowCasePage from '../../molecules/showcase';
import ContentPageInfo from '../content-page-info';
import { Container, WrapperInfo } from './styles';
import { IContentPageProps } from './types';

function ContentPage({
  children,
  reverse,
  title,
  subTitle,
  preTitle,
  infos,
  isFirstPageFontStyle,
}: IContentPageProps) {
  return (
    <Container reverse={reverse}>
      <WrapperInfo>
        <ContentPageInfo
          infos={infos}
          title={title}
          subTitle={subTitle}
          preTitle={preTitle}
          isFirstPageFontStyle={isFirstPageFontStyle}
        />
      </WrapperInfo>
      <ShowCasePage>{children}</ShowCasePage>
    </Container>
  );
}

export default ContentPage;
