import TermsOfUseComponent from '../../components/organisms/terms-of-use-component';
import Whatsapp from '../../components/organisms/whatsapp';
import { Container } from './styles';

function TermsOfUse() {
  return (
    <Container>
      <TermsOfUseComponent />
      <Whatsapp />
    </Container>
  );
}

export default TermsOfUse;
