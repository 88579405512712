import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import Text from '../../atoms/text';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2vh;

  @media (max-width: ${tabletScreen}) {
    flex-direction: column;
    grid-gap: 30px;
  }
`;

export const TextCopyRight = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  opacity: 0.5;
`;

export const TextLink = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  @media (max-width: ${tabletScreen}) {
    color: ${({ theme }) => theme.green};
    white-space: nowrap;
  }
`;

export const WrapperTerms = styled.div`
  display: flex;

  @media (max-width: ${tabletScreen}) {
    flex-direction: row;
  }

  > a {
    margin-right: 20px;
  }
`;
