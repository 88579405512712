import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import Text from '../../atoms/text';

export const Container = styled.div`
  display: grid;

  @media (max-width: ${tabletScreen}) {
    display: flex;
    flex-direction: column;
    img {
      align-self: center;
    }
  }
`;

export const TitleBenefit = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-decoration-line: underline;
  margin: 1vh 0px;
`;

export const InfoBenefit = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  max-width: 250px;
`;
