import { Form } from 'formik';
import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import Button from '../../atoms/button';
import Text from '../../atoms/text';

export const FormContainer = styled(Form)`
  display: flex;
  background-color: white;
  padding: 30px;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 38px;
  box-shadow: 0 1px 10px rgba(205, 205, 212, 0.5);

  overflow-x: hidden;
`;

export const FormTitle = styled(Text)`
  font-family: 'HalyardTextRegular';
  font-size: 24px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.secundary};

  margin-bottom: 25px;
`;

export const InputContainer = styled.div`
  display: grid;
  gap: 6px;

  @media (max-width: ${tabletScreen}) {
    display: flex;
    flex-direction: column;
  }
`;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 30%;
  gap: 15px;

  @media (max-width: ${tabletScreen}) {
    display: flex;
    flex-direction: column;
  }
`;

export const SendButton = styled(Button)`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.secundary};
  border: none;
  font-size: 18px;
  line-height: 25px;

  margin-top: 24px;
`;

export const RequiredFieldMessage = styled(Text)`
  color: ${({ theme }) => theme.bluishGray};
  font-size: 12px;
  line-height: 17px;

  margin-top: 27px;
`;
