import Facebook from '../../../assets/facebook.svg';
import Instagram from '../../../assets/instagram.svg';
import Linkedin from '../../../assets/linkedin.svg';
import Logo from '../../../assets/logo-name.svg';
import Image from '../../atoms/image';
import { Container, LogoContainer, WrapperSocial, WrapperSocials } from './styles';

function Contact() {
  return (
    <Container>
      <LogoContainer>
        <Image source={Logo} alt='logo-name' />
        <WrapperSocials>
          <WrapperSocial
            href='https://www.instagram.com/conta.futuro/?igshid=YmMyMTA2M2Y%3D'
            target='_blank'
            rel='noreferrer'
          >
            <Image source={Instagram} alt='instagram-image' />
          </WrapperSocial>

          <WrapperSocial
            href='https://www.facebook.com/Conta-Futuro-101267262324241'
            target='_blank'
            rel='noreferrer'
          >
            <Image source={Facebook} alt='wpp-image' />
          </WrapperSocial>

          <WrapperSocial
            href='https://www.linkedin.com/company/contafuturo/about/'
            target='_blank'
            rel='noreferrer'
          >
            <Image source={Linkedin} alt='linkedin-image' />
          </WrapperSocial>
        </WrapperSocials>
      </LogoContainer>
    </Container>
  );
}

export default Contact;
