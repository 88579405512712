import FingerCoin from '../../../assets/finger-coin.svg';
import InvoiceConta from '../../../assets/invoice-conta.svg';
import Money from '../../../assets/money.svg';
import PeaceSignal from '../../../assets/peace-signal.svg';

export const benefits = [
  {
    id: 1,
    title: 'ZeraDébito',
    subTitle: 'Quite dívidas de alto custo como cartão de crédito e cheque especial.',
    imageBenefitSource: PeaceSignal,
  },
  {
    id: 2,
    title: 'Ponte-salário',
    subTitle: 'Evite cair no cheque especial, tenha acesso à 40% do salário a qualquer momento.',
    imageBenefitSource: Money,
  },
  {
    id: 3,
    title: 'Educação financeira',
    subTitle:
      'A educação financeira focada nas necessidades das famílias é suporte essencial a longo prazo.',
    imageBenefitSource: InvoiceConta,
  },
  {
    id: 4,
    title: 'Encolhe><contas',
    subTitle: 'Um mercado focado em reduzir suas contas mensais como energia, gás e outras.',
    imageBenefitSource: FingerCoin,
  },
];
