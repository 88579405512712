import MobileMissionImage from '../../../assets/mission-icon.svg';
import Image from '../image';
import { Container, ImageWrapper, LabeWrapper, Label } from './styles';

function MissionMobile() {
  return (
    <Container>
      <LabeWrapper>
        <Label>Produtos inovadores para uma vida financeira mais feliz</Label>
      </LabeWrapper>
      <ImageWrapper>
        <Image source={MobileMissionImage} alt='mission-alt' />
      </ImageWrapper>
    </Container>
  );
}

export default MissionMobile;
