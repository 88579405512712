import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import Button from '../button';

export const ButtonDownloadApp = styled(Button)`
  background: ${({ theme }) => theme.primary};
  padding: 0 10px;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  color: ${({ theme }) => theme.secundary};

  @media (max-width: ${tabletScreen}) {
    padding: 0 15px;
    height: auto;
    font-size: 12px;
  }
`;

export const MobileLink = styled.a`
  height: 100%;
`;
