import sendEmailService from '../index';
import { IEmailHrIndicatedCompanyProps } from './types';

function sendEmailHrIndicatedCompany({
  hrContactEmail,
  companyName,
  hrContactName,
}: IEmailHrIndicatedCompanyProps) {
  const subject = '[Conta Futuro] - Sua empresa foi indicada para a Conta Funturo';
  const greetings = `Olá, ${hrContactName}`;

  const message = `Sua empresa, a ${companyName},
   foi indicada para impactar positivamente a vida financeira dos seus funcionários.`;

  const considerations =
    'Nosso especialista entrará em contato com você para apresentar todos os benefícios que a Conta Futuro agrega' +
    ' para as empresas participantes e, principalmente, os que gera para os funcionários';

  return sendEmailService({ email: hrContactEmail, subject, greetings, message, considerations });
}

export default sendEmailHrIndicatedCompany;
