import React, { useEffect } from 'react';

import { scroller } from 'react-scroll';

import PeaceSignal from '../../../assets/peace-signal.svg';
import useResponsive from '../../../hooks/use-responsive';
import { Container, FormTitle, SendButton, SuccessfullySignal } from './styles';
import { IndicateSuccessfullyProps } from './types';

function IndicateSuccessfully({ setSubmitted }: IndicateSuccessfullyProps) {
  const { isTablet } = useResponsive();

  useEffect(() => {
    if (isTablet) {
      scroller.scrollTo('form-indication', {});
    }
  }, [isTablet]);

  return (
    <Container>
      <SuccessfullySignal alt='peace signal' source={PeaceSignal} />
      <FormTitle>Indicação enviada com sucesso!</FormTitle>
      <SendButton onClick={setSubmitted}>Enviar nova indicação</SendButton>
    </Container>
  );
}

export default IndicateSuccessfully;
