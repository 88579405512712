import { FaWhatsapp } from 'react-icons/fa';

import { Container } from './styles';

// import { Container } from './styles';

function Whatsapp() {
  return (
    <Container>
      <a href='https://wa.me/+55085991081016' target='_blank' rel='noreferrer'>
        <FaWhatsapp size={30} color='#fff' />
      </a>
    </Container>
  );
}

export default Whatsapp;
