import styled from 'styled-components';

import Text from '../text';

export const TextCookie = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.green};
  text-align: center;
  margin-top: 8px;

  a {
    color: ${({ theme }) => theme.green};
    text-decoration: underline;
  }
`;
