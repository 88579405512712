import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import Text from '../../atoms/text';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  img {
    align-self: flex-start;
  }

  @media (max-width: ${tabletScreen}) {
    width: 100%;
    flex-direction: column;
  }
`;

export const WrapperContacts = styled.div`
  margin: 0 10vh;
  @media (max-width: ${tabletScreen}) {
    margin: 15px 0;
    padding-top: 16px;
  }
`;

export const WrapperContact = styled.div`
  display: flex;
  cursor: pointer;

  &:not(:first-child) {
    padding-top: 16px;

    @media (max-width: ${tabletScreen}) {
      padding-top: 8px;
    }
  }
`;

export const TextContact = styled(Text)`
  font-size: 16px;

  font-weight: 700;
`;
