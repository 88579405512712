import { Container, ContainerContent } from './styles';
import { IPageLayoutProps } from './types';

function PageLayout({ children, background }: IPageLayoutProps) {
  return (
    <Container background={background}>
      <ContainerContent>{children}</ContainerContent>
    </Container>
  );
}

export default PageLayout;
