import SubTitle from '../../atoms/subtitle';
import Title from '../../atoms/title';
import { Container, WrapperTextsInfo } from './styles';
import { IInfoPageProps } from './types';

function InfoPage({
  subTitle,
  title,
  centerAlign,
  imageInTitle,
  imageInTitleAfter,
  isFirstPageFontStyle,
}: IInfoPageProps) {
  return (
    <Container>
      <WrapperTextsInfo>
        {!imageInTitleAfter && imageInTitle && imageInTitle}
        <Title
          textAlign={centerAlign ? 'center' : 'start'}
          isFirstPageFontStyle={isFirstPageFontStyle}
        >
          {title}
        </Title>
        {imageInTitleAfter && imageInTitle && imageInTitle}
      </WrapperTextsInfo>

      <WrapperTextsInfo textAlign={centerAlign ? 'center' : 'flex-start'}>
        <SubTitle
          textAlign={centerAlign ? 'center' : 'start'}
          isFirstPageFontStyle={isFirstPageFontStyle}
        >
          {subTitle}
        </SubTitle>
      </WrapperTextsInfo>
    </Container>
  );
}

export default InfoPage;
