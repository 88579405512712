import { useState } from 'react';

import { isMobile } from 'react-device-detect';
import { AiOutlineClose } from 'react-icons/ai';
import { FaGripLines } from 'react-icons/fa';

import LogoName from '../../../assets/logo-name.svg';
import Image from '../../atoms/image';
import ScrollToPageButton from '../../atoms/scroll-to-page-button';
import {
  Container,
  ContainerHeader,
  ContainerLogoHeader,
  Divisor,
  MenuButton,
  MenuButtonOppend,
  OptionMenu,
  WrapperMenu,
  WrapperOptionMenu,
} from './styles';

function MenuHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [offset] = useState(0);

  const toEnablebox = offset > 250;

  return (
    <Container>
      <MenuButton onClick={() => setIsMenuOpen(true)}>
        <FaGripLines color='#000' size={30} />
      </MenuButton>
      {isMenuOpen && (
        <WrapperMenu>
          <ContainerHeader>
            <ContainerLogoHeader enableBox={toEnablebox}>
              <Image source={LogoName} alt='logo-conta' />
            </ContainerLogoHeader>
            <MenuButtonOppend onClick={() => setIsMenuOpen(false)}>
              <AiOutlineClose color='#000' size={30} />
            </MenuButtonOppend>
          </ContainerHeader>
          <WrapperOptionMenu>
            {/* <ScrollToPageButton closeMenu={() => setIsMenuOpen(false)} scrollTo='CashPass'>
              <OptionMenu>Cashpass</OptionMenu>
            </ScrollToPageButton>
            <Divisor /> */}
            <ScrollToPageButton closeMenu={() => setIsMenuOpen(false)} scrollTo='ZeraDebito'>
              <OptionMenu>Zera débito</OptionMenu>
            </ScrollToPageButton>
            <Divisor />
            <ScrollToPageButton closeMenu={() => setIsMenuOpen(false)} scrollTo='PonteSalario'>
              <OptionMenu>Ponte salário</OptionMenu>
            </ScrollToPageButton>
            <Divisor />
            {/* <ScrollToPageButton closeMenu={() => setIsMenuOpen(false)} scrollTo='faqPage'>
              <OptionMenu>Ajuda</OptionMenu>
            </ScrollToPageButton> */}
            {/* <Divisor /> */}
            <OptionMenu>
              <a
                href={!isMobile ? undefined : 'http://onelink.to/a6rbfc'}
                target='_blank'
                rel='noreferrer'
              >
                Baixar o App
              </a>
            </OptionMenu>
            <Divisor />

            <OptionMenu>
              <a href='https://wa.me/+55085991081016' target='_blank' rel='noreferrer'>
                Saiba mais
              </a>
            </OptionMenu>
            <Divisor />
          </WrapperOptionMenu>
        </WrapperMenu>
      )}
    </Container>
  );
}

export default MenuHeader;
