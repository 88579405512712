import styled from 'styled-components';

import Image from '../../components/atoms/image';
import { tabletScreen } from '../../utils/responsive';

export const Container = styled.div`
  flex: 1;

  background: linear-gradient(
    ${({ theme }) => theme.primary} 53%,
    ${({ theme }) => theme.background} 53%
  );

  padding: 15vh 0 10vh 0;
  overflow-x: hidden !important;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;

  padding: 0 10%;
  overflow-x: hidden !important;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 560px;
  margin-bottom: 10px;

  @media (max-width: ${tabletScreen}) {
    width: 100%;
  }
`;

export const ArrowUp = styled(Image)`
  margin-top: 110px;
  height: 185px;
`;

export const ThreeStar = styled(Image)`
  height: 163px;
  margin-top: 30px;
`;
