// FELIPÁO QUE FEZ

import { useEffect } from 'react';

import { Container } from './styles';

function PrivacyPolitics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <h1 style={{ textAlign: 'center' }}>Política de Privacidade</h1>
      <br />
      <p>
        {' '}
        Nesta página, descrevemos a forma como a Conta Futuro Tecnologia Soluções Financeiras e
        Serviços Ltda. processa os seus dados, seja ao acessar, ao se cadastrar ou utilizar nossa
        Plataforma e demonstrar nosso compromisso em tratar seus dados pessoais de forma
        responsável, transparente e com segurança, garantindo a sua privacidade e seus direitos.
        Entendemos a importância disso e trabalhamos para que você mantenha o controle dessas
        informações.
      </p>
      <p>
        {' '}
        Esta Política de Privacidade se aplica a todos os serviços e operações de processamento de
        dados da Conta Futuro Tecnologia Soluções Financeiras e Serviços Ltda. (“
        <u>Contafuturo</u>”) e demais empresas do grupo localizadas no Brasil.
      </p>
      <p>
        {' '}
        Neste documento, nós descrevemos o uso dos dados pessoais a fim de oferecer nossos serviços
        (“Serviços”) a usuários e visitantes do nosso Website (
        <a href='https://www.contafuturo.com.br' target='blank'>
          www.contafuturo.com.br
        </a>
        ) ou aplicativos da Contafuturo na Google Play Store e na Apple App Store (ambos referidos
        como “Aplicativo”), bem como de todos os serviços e operações no ecossistema da Contafuturo
        (Contafuturo Store, Contafuturo Educação, Contafuturo Investimentos, Parceiros) denominados
        em conjunto “Plataforma”.
      </p>
      <p>
        {' '}
        Ao interagir com a Plataforma, você está ciente da possibilidade de acesso aos seus dados
        pessoais. O uso de seus dados é realizado em conformidade com os regulamentos nacionais,
        inclusive a Lei Geral de Proteção de Dados (Lei nº 13.709/2018). Não nos responsabilizamos
        pelo uso de seus dados caso acesse links de terceiros pela Plataforma.
      </p>
      <p>
        {' '}
        <b>
          CASO VOCÊ NÃO CONCORDE COM QUAISQUER DAS DISPOSIÇÕES DESTA POLÍTICA DE PRIVACIDADE, POR
          FAVOR NÃO UTILIZE NOSSO WEBSITE E/OU NOSSO APLICATIVO.
        </b>
      </p>
      <p>
        {' '}
        Em caso de sugestões, dúvidas, reclamações sobre o tema ou caso queira exercer os direitos
        relacionados aos seus dados pessoais, entre em contato pelos nossos canais de atendimento{' '}
        <a href='https://www.contafuturo.com.br' target='blank'>
          https://www.contafuturo.com.br
        </a>{' '}
        ou diretamente com o encarregado por meio do e-mail{' '}
        <a href='mailto:legal@contafuturo.com.br'>legal@contafuturo.com.br</a>.
      </p>
      <p>
        <b>A quem esta política se aplica?</b>
      </p>
      <p>
        {' '}
        Essa Política se aplica aos (i) clientes Contafuturo, (ii) potenciais clientes e (iii)
        visitantes, pessoas naturais que acessam a nossa Plataforma, por si ou por pessoas jurídicas
        (“Você”).
      </p>
      <p> Caso Você seja</p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li>
          {' '}
          (i) um candidato à uma vaga de emprego na Contafuturo, você sempre receberá a comunicação
          através dos canais internos;
        </li>
        <li>
          {' '}
          (ii) um Funcionário da Contafuturo, você sempre receberá a comunicação através dos canais
          internos;
        </li>
        <li>
          {' '}
          (iii) um Parceiro Contafuturo, você sempre receberá a comunicação através dos canais
          internos
        </li>
      </ul>
      <p>
        <b>O que você encontrará nesta Política?</b>
      </p>
      <p> Nesta Política, Você encontrará as informações necessárias para entender sobre:</p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li> Quais informações coletamos sobre Você;</li>
        <li> Como coletamos essas informações;</li>
        <li> Para que usamos essas informações;</li>
        <li> Como interromper a coleta de informações;</li>
        <li> Como armazenamos e protegemos as informações coletadas;</li>
        <li> Como exercer os seus direitos;</li>
        <li> Em quais casos poderemos compartilhar tais informações.</li>
      </ul>
      <p>
        <b>Natureza Opcional de Concessão dos Dados</b>
      </p>
      <p>
        {' '}
        Você é livre para fornecer seus dados pessoais ao solicitar os serviços oferecidos pela
        Contafuturo. A não concessão dos dados pode impossibilitar a obtenção do serviço solicitado.
      </p>
      <p>
        {' '}
        <b>Quais Informações Nós Coletamos</b>
      </p>
      <p>
        {' '}
        Para prestarmos nossos Serviços e melhorarmos continuamente a sua experiência e o uso de
        nossos Serviços e Plataformas, coletamos informações importantes de nossos usuários. As
        informações que coletamos são as seguintes:
      </p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li>
          {' '}
          <u>Informações Cadastrais</u>: Ao se cadastrar, coletamos seu nome, e-mail, endereço,
          telefone de contato, CPF, RG, data de nascimento e sexo.
        </li>
        <li>
          {' '}
          <u>Informações Complementares</u>: Quando Você solicita cotações ou propostas, poderemos
          solicitar informações complementares, tais como idade, nacionalidade, estado civil, regime
          civil e filiação (nome da mãe e nome do pai), informações profissionais e sobre
          escolaridade a depender do serviço ou produto solicitado.
        </li>
        <li>
          {' '}
          <u>Informações Financeiras</u>: Além disso, também iremos solicitar informações
          financeiras relevantes para que possam ser oferecidos produtos ou serviços adequados às
          suas necessidades e poderemos consultar bureaus de crédito e fontes externas para
          verificar a sua capacidade de pagamento, a veracidade das informações, proteção ao crédito
          e prevenir fraudes. Tais informações financeiras poderão incluir sua renda, suas despesas,
          débitos em haver, bens de sua propriedade, dados bancários, outros dados do seu patrimônio
          e o seu score de crédito, melhor descritas abaixo em “
          <u>Como Coletamos Informações Sobre Você</u>”.
        </li>
        <li>
          {' '}
          <u>Informações de Perfil</u>: Podemos tratar também informações sobre as suas preferências
          de compra e preferências de navegação na internet a partir da Plataforma para te conhecer
          melhor e aprimorar os nossos serviços.
        </li>
        <li>
          {' '}
          <u>Atividades de navegação</u>: Poderemos coletar informações e dados sobre a sua
          experiência de navegação em nosso Website ou Aplicativo. Ao longo de sua visita no Website
          ou Aplicativo, poderão ser automaticamente coletados o seu Internet Protocol (IP), sua
          localização, seu tipo de computador, tipo de celular, tipo de navegador, páginas e
          serviços acessados, entre outros.
        </li>
      </ul>
      <p>
        <b>Como Coletamos Informações Sobre Você</b>
      </p>
      <p>
        {' '}
        Nós poderemos coletar e armazenar informações sobre Você que mencionamos acima, diretamente
        de você ao se registrar ou utilizar algum de nossos Serviços. Alguns dados, nós coletamos
        automaticamente, como quando navega em nossas páginas e utiliza nossa Plataforma. Além
        disso, podemos coletar informações através de terceiros.
      </p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li>
          {' '}
          <b>Por meio do seu cadastro e solicitação de cotações ou propostas.</b> Por você mesmo, ao
          realizar seu cadastro ou utilizar o Website ou Aplicativo ou das suas solicitaçes de
          cotações e propostas de instituições financeiras, companhias de seguros, operadoras de
          meios de pagamento ou bandeira de cartão de crédito (“
          <u>Instituições Parceiras</u>”).
        </li>
        <li>
          {' '}
          <b>Por meio de terceiros e dados públicos.</b> Para fornecer os Serviços solicitados e
          enviar propostas para Você, coletaremos informações que lhe são relativas e que constem do
          Sistema de Informações de Crédito do Banco Central (“SCR”), dos órgãos de proteção ao
          crédito, de bases de dados públicas e privadas, conforme aplicável, e demais fontes que
          possam auxiliar na definição de seu risco de crédito para verificar a sua capacidade de
          pagamento, bem como para garantir a sua segurança e para fins de prevenção de fraudes, de
          proteção ao crédito. Por ex., para validar a sua identidade. Ademais, poderemos coletar
          informações para a execução de procedimentos de prevenção à lavagem de dinheiro e
          financiamento ao terrorismo - Know Your Client (“KYC”) -, inclusive aqueles dispostos nos
          termos das leis anticorrupção, antiterrorismo, contra a lavagem de capitais e a evasão de
          divisa. Por exemplo, para verificar se Você faz parte de alguma lista de Pessoa
          Politicamente Exposta – PEP – e demais listas de restrição – OFAC e demais listas
          internacionais).
        </li>
        <li>
          {' '}
          <b>Por meio de atividades de navegação e da utilização de nossa Plataforma.</b> Poderemos
          coletar informações e dados sobre a sua experiência de navegação em nosso Website ou
          Aplicativo através de cookies (<i>mais informações abaixo</i>), <i>web beacons</i> e/ou
          outras tecnologias de monitoramento semelhantes, Estas tecnologias permitem, por exemplo,
          lembrar-se de Você e de suas preferências, verificar quais páginas você visita e quando
          você visita tais páginas, além de outros sites que foram visitados antes, entre outras,
          além de coletar informações que podem ser usadas para oferecer um conteúdo personalizado.{' '}
          <i>Cookies</i> são identificações da interação com nosso Website ou nossas publicidades
          que são transferidas para o aparelho do usuário visando reconhecê-lo na próxima navegação.
          Utilizamos cookies para proporcionar uma melhor experiência em nosso Website ou Aplicativo
          e viabilizar recursos personalizados como recomendações de infográficos, artigos,
          calculadoras, produtos e serviços financeiros, publicidades e informações adicionais do
          seu interesse. <i>Cookies</i> e outras tecnologias semelhantes ativadas por terceiros
          poderão ser encontrados em algumas páginas do Website, e utilizadas por terceiros,
          aplicando-se as políticas e termos de uso destes terceiros. Não controlamos estas
          tecnologias e, desta forma, esta Política não se aplica a estes cookies e tecnologias de
          terceiros.
        </li>
      </ul>
      <p>
        {' '}
        Para não ter suas informações pessoais coletadas, recomendamos que Você não acesse o Website
        e Aplicativo, bem como não solicite quaisquer propostas e cotações de nossos serviços e
        produtos.
      </p>
      <p>
        {' '}
        Caso deseje interromper a coleta de informações pelos <i>cookies</i>, Você deverá
        desabilitar o salvamento de cookies em seu navegador de internet, apagá-los e gerenciar sua
        utilização por meio da configuração do navegador que utiliza para acessar o Website, ou Você
        deverá sair da sua sessão do Aplicativo e não o utilizar mais.
      </p>
      <p>
        {' '}
        Caso opte por desabilitar alguns recursos de navegação a Plataforma poderá deixar de
        funcionar.
      </p>
      <p>
        {' '}
        Os principais navegadores de internet possibilitam ao usuário gerenciar a utilização dos
        cookies em sua máquina. Você precisa fazer o procedimento em cada um deles separadamente:
      </p>
      <p>
        {' '}
        <a href='https://support.google.com/chrome/answer/95647?hl=pt-BR'>Google Chrome</a>{' '}
        <a href='https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies'>
          Internet Explorer
        </a>{' '}
        <a href='https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies'>
          Microsoft Edge
        </a>{' '}
        <a href='https://support.mozilla.org/pt-BR/kb/protecao-aprimorada-contra-rastreamento-firefox-desktop?redirectslug=ative-e-desative-os-cookies-que-os-sites-usam&amp;redirectlocale=pt-BR'>
          Mozilla Firefox
        </a>{' '}
        <a href='https://support.apple.com/pt-br/guide/safari/sfri11471/mac'>Safari</a>{' '}
        <a href='https://help.opera.com/en/latest/web-preferences/#cookies'>Opera</a>
      </p>
      <p>
        <b>Para Que Usamos Essas Informações</b>
      </p>
      <p>
        {' '}
        Todas as informações coletadas pela Contafuturo na Plataforma servem para permitir que
        prestemos serviços cada vez melhores para o usuário, ou seja, mais adequados, mais
        competitivos e mais completos.
      </p>
      <p> Nós poderemos utilizar essas informações (pessoais ou não) principalmente para:</p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li> Criar a sua conta junto à Contafuturo;</li>
        <li>
          {' '}
          Completar as suas solicitações de cotações, propostas de produtos ou serviços oferecidos
          pelas Instituições Parceiras;
        </li>
        <li>
          {' '}
          Viabilizar as suas compras nas <i>plataformas</i> da Contafuturo;
        </li>
        <li>
          {' '}
          Análise de crédito de acordo com as políticas de crédito da Contafuturo e consulta sobre a
          capacidade de pagamento do usuário em plataformas de <i>bureaus</i> de crédito, bem como
          proteção ao crédito;
        </li>
        <li> Traçar perfis e tendências demográficas de uso do Website ou Aplicativo;</li>
        <li>
          {' '}
          Entrar em contato com Você para confirmar ou verificar as informações que Você nos
          forneceu e garantir a segurança os usuários da Contafuturo;
        </li>
        <li>
          {' '}
          Entrar em contato com Você para fins de marketing sobre os produtos e serviços ofertados
          pela Contafuturo e por Instituições Parceiras;
        </li>
        <li>
          {' '}
          Tratativas sobre dúvidas, reclamações ou sugestões enviadas nos canais de atendimento da
          Contafuturo, bem como na resolução de problemas;
        </li>
        <li>
          {' '}
          Garantir que o Website ou Aplicativo se mantenha sempre interessante e útil para Você, o
          que poderá incluir a personalização de anúncios e sugestões de conteúdos, produtos ou
          serviços.
        </li>
        <li>
          {' '}
          Cruzar informações através do Google Analytics para analisar os perfis dos usuários e
          oferecer serviços mais personalizados;
        </li>
        <li> Aprimorar o modelo de precificação e modelo de crédito da Contafuturo;</li>
        <li>
          {' '}
          Proteger a segurança e a integridade da Plataforma, de nossos direitos e propriedades, e
          de nossa base de dados;
        </li>
        <li> Conduzir diligências internas relativas aos negócios da Contafuturo;</li>
        <li> Desenvolver, melhorar e oferecer serviços e produtos de terceiros;</li>
        <li>
          {' '}
          Exercer os direitos da Contafuturo em processos judiciais e administrativos, conforme
          necessário;
        </li>
        <li>
          {' '}
          Atender obrigações legais ou regulatórias e solicitações de autoridades, se pertinentes,
          bem como a verificação de fraudes, crimes financeiros e demais ilícitos.
        </li>
      </ul>
      <p>
        <b> Compartilhamento de Informações</b>
      </p>
      <p>
        {' '}
        A viabilidade de certos serviços prestados pela Contafuturo só ocorre pelo compartilhamento
        de algumas dessas informações, o que fazemos com responsabilidade e seguindo rigorosos
        parâmetros, principalmente de segurança da informação. Abaixo, citamos os casos nos quais o
        compartilhamento de informações se faz necessário:
      </p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li>
          {' '}
          <b>Instituições Parceiras e Prestadores de Serviços.</b> Para que Você possa receber
          cotações e propostas de produtos e serviços financeiros tais como cartões de crédito,
          seguros, empréstimos, financiamentos, investimentos e outros, a Contafuturo e as
          Instituições Financeiras e Instituições Parceiras (tais como sua empresa no caso de
          empréstimo consignado) necessitam ter acesso a informações pessoais e não pessoais. Apenas
          assim é possível que Você receba propostas adequadas ao que Você procura. As Instituições
          Parceiras e Prestadores de Serviços terão acesso a essas informações apenas na medida em
          que forem necessárias para oferecer produtos e serviços que Você solicitou ou manifestou
          interesse e fazer as devidas validações cadastrais e financeiras.
        </li>
        <li>
          {' '}
          <b>
            Fornecedores dos produtos vendidos nas <i>plataformas</i> da Contafuturo.
          </b>{' '}
          A Contafuturo compartilhará os dados pessoais dos usuários que adquirirem os produtos
          vendidos no marketplace com os fornecedores de tal produto para emissão de nota fiscal e
          entrega do produto.
        </li>
        <li>
          {' '}
          <b>Prestadores de serviço de tecnologia.</b> A Contafuturo poderá compartilhar dados
          pessoais com empresas que forneçam suporte ou infraestrutura de tecnologia para
          viabilização do Website, Aplicativo e dos produtos e serviços ofertados pela Contafuturo.
        </li>
        <li>
          {' '}
          <b>Novos negócios:</b> No contínuo desenvolvimento do nosso negócio, processos de
          aquisição e fusão de empresas, estabelecimento de parcerias comerciais, joint ventures e
          outros negócios podem ocorrer. Nesses negócios, informações dos respectivos usuários
          também são transferidas, mas ainda assim, será mantida a Política de Privacidade.
        </li>
        <li>
          {' '}
          <b>Ordem judicial.</b> A Contafuturo pode compartilhar dados pessoais em caso de
          requisição judicial.
        </li>
        <li>
          {' '}
          <b>Com a autorização do usuário.</b> Em demais casos, havendo a necessidade de
          compartilhamento das informações e caso a Contafuturo não possa compartilhar com base em
          outra base legal estabelecida pela legislação de proteção de dados, enviaremos ao usuário
          uma notificação solicitando o seu consentimento.
        </li>
      </ul>
      <p>
        {' '}
        Ao solicitar uma simulação de crédito no Website ou Aplicativo, o usuário concorda e
        autoriza que o Banco ou a Instituição Financeira Parceira tenha acesso e consulte seus dados
        de depósitos, aplicações em outras instituições financeiras e informações a seu respeito
        constantes junto aos órgãos de proteção ao crédito tais como SPC e Serasa. Você também deve
        estar ciente de que as suas informações serão tratadas por essas instituições de acordo as
        suas próprias políticas de privacidade. Não submeta pedidos de propostas e cotações caso não
        concorde com a forma com a qual as Instituições Financeiras Parceiras tratam os seus dados
        pessoais.
      </p>
      <p>
        {' '}
        As informações armazenadas no Website e no Aplicativo poderão ser fornecidas a terceiros,
        tais como instituições financeiras e demais agentes de mercado, para fins de análise,
        elaboração e gerenciamento de Propostas de Captação de Recursos, com o objetivo de gerar uma
        Proposta de Crédito, em consonância com a Política de Avaliação de Crédito. As informações
        submetidas poderão ser utilizadas para manutenção dos Serviços de Cobrança das operações de
        crédito da Contafuturo, das Instituições Financeiras e futuros Credores dos créditos
        eventualmente cedidos, gerenciamento de aplicações, recomendação de investimentos e
        manutenção de um banco de dados.
      </p>
      <p>
        <b>Como Armazenamos e Protegemos as Informações Coletadas</b>
      </p>
      <p>
        {' '}
        Após coletarmos os dados e informações mencionados nessa Política de Privacidade, iremos
        armazená-los sob as mais rígidas práticas de segurança de informação. Nosso banco de dados
        terá seu acesso criteriosamente restringido a apenas alguns colaboradores habilitados, que
        são obrigados por contrato a preservar a confidencialidade de suas informações.
      </p>
      <p>
        {' '}
        Iremos sempre empregar nossos melhores esforços para garantir que suas informações serão
        sempre tratadas de acordo com o estabelecido nesta Política de Privacidade e com o disposto
        na legislação aplicável de proteção de dados. Ainda assim, Você deve saber que nós não somos
        responsáveis pela hipótese de quebra de segurança de nossa base de dados que cause a
        divulgação ou acesso indevido de informações do usuário. Em tal caso, nenhuma compensação
        por parte da Contafuturo será devida a Você.
      </p>
      <p>
        <b>Seus Direitos</b>
      </p>
      <p>
        {' '}
        Os usuários possuem direitos no que se refere às suas informações pessoais, dentre eles:
      </p>
      <ul style={{ paddingRight: '5%', paddingLeft: '5%' }}>
        <li>
          {' '}
          <b>Confirmação da existência de tratamento de dados pessoais.</b> Mediante requisição do
          usuário, a Contafuturo concederá a confirmação da existência de tratamento de dados
          pessoais, nos termos da legislação aplicável.
        </li>
        <li>
          {' '}
          <b>Acesso aos dados pessoais.</b> O usuário pode requisitar acesso aos seus dados pessoais
          coletados e que estejam armazenados pela Contafuturo.
        </li>
        <li>
          {' '}
          <b>Correção de dados incompletos, inexatos ou desatualizados.</b> O usuário poderá, a
          qualquer momento, alterar e editar os seus dados pessoais.
        </li>
        <li>
          {' '}
          <b>Informações sobre uso compartilhado de dados.</b> As informações sobre o
          compartilhamento de dados pessoais encontram-se nesta Política de Privacidade. A
          Contafuturo coloca-se à disposição do usuário para a hipótese de esclarecimentos
          complementares.
        </li>
        <li>
          {' '}
          <b>Revisão de decisão automatizada.</b> O usuário poderá solicitar a revisão de decisões
          tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus
          interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional,
          de consumo e de crédito ou os aspectos de sua personalidade.
        </li>
        <li>
          {' '}
          <b>Eliminação dos dados pessoais.</b> O usuário poderá solicitar a eliminação de dados
          pessoais que tenham sido coletadas pela Contafuturo a partir do consentimento do usuário,
          a qualquer momento, mediante manifestação gratuita e facilitada. A eliminação dos dados
          pessoais somente poderá ser completa para dados pessoais que não sejam necessários para a
          Contafuturo cumprir com obrigações legais, contratuais, para proteção de seu legítimo
          interesse e nas demais hipóteses admitidas legalmente.
        </li>
        <li>
          {' '}
          <b>Revogação do consentimento.</b> O usuário poderá revogar o consentimento que tenha dado
          à Contafuturo para tratamento dos seus dados pessoais para certas finalidades, a qualquer
          momento, mediante manifestação gratuita e facilitada. Importante informar que os
          tratamentos realizados anteriormente à revogação do consentimento são ratificados e que o
          pedido de revogação não implicará a eliminação dos dados pessoais anteriormente tratados e
          que sejam mantidos pela Contafuturo com base em outros fundamentos legais.
        </li>
        <li>
          {' '}
          <b>Portabilidade:</b> A Autoridade Nacional de Proteção de Dados (ANPD) irá regulamentar o
          direito de portabilidade, a partir de então Você poderá solicitar à Contafuturo a
          portabilidade de seus dados para outro prestador de serviço.
        </li>
        <li>
          {' '}
          <b>Exercício regular do direito pelo Titular dos Dados Pessoais:</b> Com a devida
          constituição da ANPD, Você poderia peticionar por seus direitos perante a Autoridade
          Nacional de Proteção de Dados.
        </li>
      </ul>
      <p>
        {' '}
        Caso você deseje exercer qualquer um desses direitos, Você pode entrar em contato com o
        Encarregado através dos canais atendimento disponível diretamente com o nosso Encarregado
        através do e-mail <a href='mailto:legal@contafuturo.com.br'>legal@contafuturo.com.br</a>. No
        caso de alguma solicitação sobre seus direitos, podemos solicitar comprovação da sua
        identidade, como medida de segurança e prevenção à fraude.
      </p>
      <p>
        <b>Links para Sites de Terceiros</b>
      </p>
      <p>
        {' '}
        Através da Plataforma, a Contafuturo poderá disponibilizar links para outros sites na
        Internet. A CONTAFUTURO NÃO SE RESPONSABILIZA POR ESTES WEBSITES E CONTEÚDOS E, AINDA, NÃO
        COMPARTILHA DADOS, SUBSCREVE, MONITORA, VALIDA OU ACEITA A FORMA COMO ESSES WEBSITES OU
        FERRAMENTAS DE ARMAZENAMENTO DE CONTEÚDO COLETAM, PROCESSAM E TRANSFEREM SUAS INFORMAÇÕES
        PESSOAIS. Recomendamos que Você consulte as respectivas políticas de privacidade de tais
        websites para se informar adequadamente a respeito do uso de suas informações pessoais.
      </p>
      <p>
        {' '}
        <b>Alterações nesta Política de Privacidade</b>
      </p>
      <p>
        {' '}
        Reservamo-nos o direito de corrigir ou atualizar esta Política de Privacidade
        periodicamente. Ao atualizarmos esta Política de Privacidade, atualizamos também a data na
        parte superior desta Política de Privacidade.
      </p>
      <p>
        {' '}
        Em situações relevantes, principalmente na eventual modificação das finalidades para os
        quais os dados tenham sido coletados, o usuário será informado quanto às alterações
        realizadas. A nova Política de Privacidade entrará em vigor imediatamente após a publicação.
        Se Você não concordar com a política revisada, altere suas preferências no Website ou
        Aplicativo ou deixe de utilizá-los. Se continuar acessando depois que essas alterações
        entrarem em vigor, Você confirma que foi informado sobre a Política de Privacidade e que
        concorda com ela em sua versão revisada.
      </p>
      <p>
        <b>Contato do Controlador e do Encarregado</b>
      </p>
      <p>
        {' '}
        A Contafuturo Tecnologia Soluções Financeiras e Serviços Ltda., sociedade inscrita no
        CNPJ/ME sob nº 38.064.618/0001-98, com sede na cidade do Eusebio, estado do Ceará, na Rua
        Calixto Machado, 21, Sala N Altos, Pires Façanha, Eusebio – CE, 61760-000 é a controladora
        dos dados pessoais dos usuários tratados no âmbito desta Política de Privacidade.
      </p>
      <p>
        {' '}
        Caso tenha qualquer dúvida após a leitura desta Política, a Contafuturo disponibiliza alguns
        canais de contato para diversos temas, nosso Encarregado através do e-mail{' '}
        <a href='mailto:legal@contafuturo.com.br'>legal@contafuturo.com.br</a>.
      </p>
      <p>
        <b>Termos de Uso</b>
      </p>
      <p>
        {' '}
        Esta Política de Privacidade faz parte dos nossos Termos de Uso, disponíveis em{' '}
        <a href='https://www.contafuturo.com/terms-of-use'>
          https://www.contafuturo.com/terms-of-use
        </a>{' '}
        (“Termos”). Qualquer termo em maiúscula, porém indefinido, nesta Política terá o significado
        que lhe é atribuído nos Termos.
      </p>
    </Container>
  );
}

export default PrivacyPolitics;
