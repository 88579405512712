import AppleStore from '../../../assets/apple-store.svg';
import PlayStore from '../../../assets/play-store.svg';
import Store from '../store';
import { Container, StoresContainer } from './styles';
import { IStoresProps } from './types';

function Stores({ isVertical = false }: IStoresProps) {
  const appleStoreUrl = 'https://apps.apple.com/us/app/conta-futuro/id1571143063';
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.contafuturo.contafuturo';
  return (
    <Container isVertical={isVertical}>
      {isVertical && <span>Baixe o app</span>}
      <StoresContainer isVertical={isVertical}>
        <Store sourceImgStore={AppleStore} url={appleStoreUrl} />
        <Store sourceImgStore={PlayStore} url={playStoreUrl} />
      </StoresContainer>
    </Container>
  );
}

export default Stores;
