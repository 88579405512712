import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import Text from '../../atoms/text';

interface IContainer {
  align: string;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align};
`;

export const TextPreTitle = styled(Text)`
  font-family: 'HalyardTextRegular';
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  background-color: ${({ theme }) => theme.primary};
  padding: 0 10px;

  @media (max-width: ${tabletScreen}) {
    width: fit-content;
    font-size: 12px;
    display: inline-block;
    align-self: center;
  }
`;

export const WrapperInfoPage = styled.div`
  flex-direction: row;
`;

export const WrapperInfos = styled.div`
  padding: 2vh 0px;
`;
