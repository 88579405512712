import { Container } from './styles';
import { IInfosListPageProps } from './types';

function InfosListPage({ infos }: IInfosListPageProps) {
  return (
    <Container>
      {infos.map((textInfo) => (
        <li key={textInfo}>√ {textInfo}</li>
      ))}
    </Container>
  );
}

export default InfosListPage;
