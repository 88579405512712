import styled from 'styled-components';

import { tabletScreen } from '../../utils/responsive';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperCreditlInfo = styled.div`
  @media (max-width: ${tabletScreen}) {
    width: 100%;
    flex-direction: column;
  }
`;
