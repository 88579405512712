import Input from '../../atoms/input';
import InputMask from '../../atoms/input-mask';
import { Container, Error, Label } from './styles';
import { ILabeledInputProps } from './types';

function LabeledInput({ label, required, field, error, mask }: ILabeledInputProps) {
  return (
    <Container error={!!error}>
      <Label>
        {label}
        {required && '*'}
      </Label>

      {mask ? (
        <InputMask mask={mask} guide={false} error={error ? true : undefined} {...field} />
      ) : (
        <Input {...field} error={!!error} />
      )}

      {error && <Error>{error}</Error>}
    </Container>
  );
}

export default LabeledInput;
