import React, { useState } from 'react';

import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import sendEmailAcknowledgmentIndicator from '../../../services/mail/acknowledgment-indicator';
import sendEmailHrContaFuturo from '../../../services/mail/hr-conta-futuro';
import sendEmailHrIndicatedCompany from '../../../services/mail/hr-indicated-company';
import IndicateFormFields from '../indicate-form-fields';
import IndicateSuccessfully from '../indicate-successfully';
import { FormContainer } from './styles';
import { IFormValues } from './types';

function IndicateForm() {
  const [submitted, setSubmitted] = useState(false);

  const initialValues: IFormValues = {
    name: '',
    email: '',
    phone: '',
    hrContactName: '',
    hrContactEmail: '',
    hrContactPhone: '',
    companyName: '',
  };

  const handleSubmitEmails = ({
    email,
    name,
    phone,
    hrContactName,
    hrContactEmail,
    hrContactPhone,
    companyName,
  }: IFormValues) => {
    return Promise.all([
      sendEmailHrContaFuturo({
        email,
        name,
        phone,
        hrContactName,
        hrContactEmail,
        hrContactPhone,
        companyName,
      }),
      sendEmailHrIndicatedCompany({ hrContactName, hrContactEmail, companyName }),
      sendEmailAcknowledgmentIndicator({ email, companyName }),
    ]);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: IFormValues, helpers: FormikHelpers<IFormValues>) => {
        handleSubmitEmails(values)
          .then(() => {
            helpers.setSubmitting(false);
            helpers.resetForm();
            setSubmitted(true);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
          });
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Campo obrigatório'),
        email: Yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
        phone: Yup.string().required('Campo obrigatório'),
        hrContactName: Yup.string().required('Campo obrigatório'),
        hrContactEmail: Yup.string()
          .email('Formato de e-mail inválido')
          .required('Campo obrigatório'),
        hrContactPhone: Yup.string().required('Campo obrigatório'),
        companyName: Yup.string().required('Campo obrigatório'),
      })}
    >
      {(props) => {
        const { handleSubmit } = props;
        return (
          <FormContainer onSubmit={handleSubmit}>
            {submitted ? (
              <IndicateSuccessfully setSubmitted={() => setSubmitted(false)} />
            ) : (
              <IndicateFormFields {...props} />
            )}
          </FormContainer>
        );
      }}
    </Formik>
  );
}

export default IndicateForm;
