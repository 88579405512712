import FooterContent from '../../components/organisms/footer-content';
import { Container } from './styles';

function Footer() {
  return (
    <Container>
      <FooterContent />
    </Container>
  );
}

export default Footer;
