import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.button`
  background: transparent;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.secundary};
  border-radius: 8px;
  padding: 12px 8px;
  color: ${({ theme }) => theme.background};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  transition: background-color 0.2s;
  &:hover {
    background: ${({ theme }) => shade(0.2, theme.primary)};
  }
`;
