import styled from 'styled-components';

import Image from '../../components/atoms/image';
import { tabletScreen } from '../../utils/responsive';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${tabletScreen}) {
    place-content: center;
  }
`;

interface IWrapperFutureMarketInfo {
  width: string;
  justify: string;
}
export const WrapperFutureMarketInfo = styled.div<IWrapperFutureMarketInfo>`
  width: ${({ width }) => width};
  display: flex;
  justify-content: ${({ justify }) => justify};
  flex-direction: column;
`;

export const ImageFutureMarket = styled(Image)`
  width: 100%;
  height: 100%;

  @media (max-width: ${tabletScreen}) {
    margin: 20px 0 20px;
  }
`;
