import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import Text from '../../atoms/text';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  img {
    align-self: flex-start;
  }

  @media (max-width: ${tabletScreen}) {
    width: 100%;
    flex-direction: column;
    padding-top: 16px;
    margin-top: 46px;
    margin-bottom: 46px;
  }
`;

export const LogoContainer = styled.div``;

export const WrapperSocials = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 32px;

  @media (max-width: ${tabletScreen}) {
    padding-top: 16px;
  }
`;

export const WrapperSocial = styled.a`
  display: flex;
  margin-right: 15px;
`;

export const TextContact = styled(Text)`
  font-size: 16px;
  margin-left: 16px;
  font-weight: 700;
`;
