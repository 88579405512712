import styled from 'styled-components';

import { tabletScreen } from '../../utils/responsive';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 15vh;
  justify-content: center;

  @media (min-width: ${tabletScreen}) {
    width: 100%;
  } ;
`;
