import styled, { css } from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';

interface IStoresContainer {
  isVertical?: boolean;
}
export const Container = styled.div<IStoresContainer>`
  display: flex;
  flex-direction: column;

  a {
    margin-top: 10px;
  }

  @media (max-width: ${tabletScreen}) {
    ${({ isVertical }) =>
      isVertical &&
      css`
        margin-top: 39px;
      `}

    a {
      margin-top: 20px;
    }
  }
`;

export const StoresContainer = styled.div<IStoresContainer>`
  display: flex;
  flex-direction: row;
  grid-gap: 15px;

  ${({ isVertical }) =>
    isVertical &&
    css`
      flex-direction: column;
      grid-gap: 0;
    `}

  @media (max-width: ${tabletScreen}) {
  }
`;
