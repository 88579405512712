import styled from 'styled-components';

import { tabletScreen } from '../../../utils/responsive';
import { IContainerContentPage } from './types';

export const Container = styled.div<IContainerContentPage>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  overflow-x: hidden;

  @media (max-width: ${tabletScreen}) {
    flex-direction: column;
  }
`;

export const WrapperInfo = styled.div`
  width: 50%;

  @media (max-width: ${tabletScreen}) {
    width: 100%;
  }
`;
